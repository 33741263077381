export const lang = {
	//title为每个页面的标题
	title: '领券中心',
	coupon_center: '优惠券中心',
	coupon_trip: '省钱更多，领券更多',
	limited_coupon: '限时抢券',
	stacked_use: '叠加使用',
	variety: '种类多样',
	my_coupon: '我的优惠券',
	receive: '立即领取',
	to_use: '去使用',
	previous: '上一页',
	next: '下一页',
	no_coupon: '暂无优惠券',
	no_threshold_coupon: '无门槛优惠券',
	full: '满',
	available: '可使用',
	valid_until: '有效期至',
	validity_one: '领取之日起',
	validity_two: '日内有效',
}
