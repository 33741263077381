export const lang = {
	//title为每个页面的标题
	title: 'Member Center',
	member: 'Member Center',
	welcome: 'Welcome',
	info: 'Personal details',
	security: 'Account security',
	deliveryAddress: 'Delivery address',
	collection: 'Collection',
	footprint: 'My Tracks',
	trading: 'Trading center',
	myorder: 'My order',
	refund: 'Refund/after sale',
	verification: 'verification',
	verificationList: 'verificationList',
	account: 'Account',
	money: 'Money',
	record: 'Record',
	coupon: 'Cupon',
	score: 'Score',
	acclist: 'Account list',
	
	notlog: 'not logged in',
	grow: 'grow',
	coupons: 'coupon',
	scores: 'socre',
	moneys: 'money',
	
	collection2: 'Focus on shop',
	collection3: 'Focus on goods',
	details: 'View details',
	daifukuan: 'obligation',
	daifahuo: 'wait for sending',
	daishouhuo: 'wait for receiving',
	daipingjia: 'wait for evaluation',
}
