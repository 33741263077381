export const lang = {
	//title为每个页面的标题
	title: 'Register',
	label: 'User Register',
	username: 'Password register',
	emailRegister: 'Email dynamic code register',
	mobileRegister: 'Mobile dynamic code register',
	welcome: 'Welcome to register',
	account: 'Please enter the user name',
	password: 'Please enter the user password',
	rePassword: 'Please enter the confirm password',
	reNoPassword: 'Confirm password incorrect!',
	email: 'Please enter the email',
	email_dynacode:'Please enter the email dynacode',
	payDynacodeText: 'Get dynamic code',
	code_trip: 'Please enter the verify code',
	mobile_tips: 'Please enter a valid phone number',
	mobile: 'Please enter the mobile',
	mobile_dynacode:'Please enter the mobile dynacode',
	button: 'Register',
	login_button: 'Login now',
	read_agree: 'Read and agree', 
	aggrement: '<<Service agreement>>',
	exist_account: 'Existing account, ', 
	read_agree_trip: 'Please read the agreement first and tick',
}
