export const lang = {
	//title为每个页面的标题
	title: '找回密码',
	process_one: '输入电子邮箱',
	process_two: '重置密码',
	process_three: '完成',
	email_trip: '请输入注册的电子邮箱',
	next_step: '下一步',
	vercode_trip: '请输入验证码',
	dynacode_trip: '请输入邮箱动态码',
	passwd_trip: '请输入新的登录密码',
	repasswd_trip: '请再次输入新密码',
	reset_password: '重置密码',
	reset_success: '重置密码成功',
	re_login: '重新登录',
}
