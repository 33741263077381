export const lang = {
	//title为每个页面的标题
	title: 'Edit shipping address',
	first_name: 'First name',
	last_name: 'Last name',
	first_placeholder: 'Pinyin or English name',
	last_placeholder: 'Pinyin or English surname',
	address: 'Address',
	address_placeholder: 'Locate to the community, street, office building',
	city: 'City',
	country: 'Country / region',
	country_placeholder: 'Please select a country',
	province_placeholder: 'Please select a province/territory',
	post_code: 'Zip code',
	postcode_placeholder: 'Please correspond to the city',
	mobile: 'Mobile',
	mobile_placeholder: "Consignee's mobile phone number",
	is_default: 'is default',
	save: 'Save',
	yes: 'Yes',
	no: 'No',
	mobile_tips: 'Mobile number cannot be empty',
	region_tips: 'Please select the region',
	country_tips: 'Please select a country',
	first_name_tips: "Please enter the recipient's name",
	last_name_tips: "Please enter the consignee's last name",
	post_code_tips: 'Please enter the zip code',
	address_tips: 'Please enter detailed address',
	phone_tips: 'Please enter the mobile',
}
