export const lang = {
	//title为每个页面的标题
	title: '待付款订单',
	account: '请输入登陆邮箱',
	email_code: '邮箱动态码登录',
	loginsubmit: '登录',
	login_success: '登陆成功',
	bindsubmit: '绑定',
	goods_item: '商品信息',
	price: '价格',
	quantity: '数量',
	subtotal: '小计',
	shipping_address: '收货地址',
	goods_amount: '商品金额',
	freight: '运费',
	coupon: '优惠券',
	shop_coupon: '店铺优惠券',
	platform_coupon: '平台优惠券', 
	use_balance: '使用余额',
	discount: '优惠',
	shop_discount: '店铺优惠',
	select_coupon: '选择优惠券',
	select_platform_coupon: '选择平台优惠券',
	name: '名称',
	use: '使用',
	deadline: '期限',
	cancel: '取消',
	confirm_select: '确认选择',
	settlement: '订单结算',
	amount_payable: '应付金额', 
	add_shipping_address: '添加收货地址',
	delete_address: '确定要删除该地址吗？',
	more_address: '更多收货地址',
	edit_shipping_address: '编辑收货地址',
	email: '联系信息',
	email_trip: '请输入联系信息',
	contact_trip: '请输入联系信息',
	login: '登录',
	existing_account: '已有账户？',
	consignee: '收货人',
	first_name_trip: '请输入名(First name)',
	last_name_trip: '请输入姓(Last name)',
	address: '详细地址',
	address_trip: '请精确到楼栋门牌号',
	address_s_trip: '请输入地址信息',
	suburb: '区(Suburb)',
	suburb_trip: '收货人所在的区(Suburb)',
	country_state: '国家/州',
	select_country: '请选择国家(Country)',
	select_state: '请选择州(State)',
	post_code: '邮政编码',
	post_code_trip: '请输入正确的邮编',
	phone: '电话(Phone)',
	phone_trip: '请输入收货人手机号(Phone),例如：0* **** ****',
	use_balance_or: '是否使用余额',
	use_balance: '使用余额',
	use_no_balance: '不使用余额',
	gift: '赠品',
	delivery_method: '配送方式',
	no_select_address: '请先添加收货地址',
	no_use_coupon: '不使用优惠券',
	use_coupon: '已选择优惠券，已优惠',
	full_reduction: '满减',
	full_amount_post: '满额包邮',
	freeshipping: '包邮',
	full: '满',
	buyer_message: '买家留言',
	message: '留言前建议先与商家协调一致',
	payable_amount: '件，应付金额',
	a_payable_amount: '共',
	available: '可用',
	all_available: '任意金额可用',
	order_submit: '订单提交中...',
	select_shipping_address: '请先选择您的收货地址',
	discountcode: '折扣码',
	discountcode_trip: '请输入折扣码',
	discountcode_deduction: '折扣码抵扣金额',
	use_point: '使用积分',
	use_no_point: '不使用积分',
	point_deduction: '积分抵扣金额',
}
