export const lang = {
	//公共
	common: {
		//语言包名称
		name: '英文',
		metaTitle: 'Australian Asian Appliances Superstore',
		login: 'Login',
		register: 'Register',
		logout: 'Log out',
		myOrder: 'My order',
		myHistory: 'My history',
		myAttention: 'My attention',
		help: 'Help center',
		notice: 'Notice',
		mobile_1: 'Mobile store',
		goods: "Goods",
		shop: "Shop",

		allCategory: 'All category',
		
		//订阅
		subTitle: 'Join us to get the latest discount information',
		subHint: 'Please enter your email.',
		subBtn: 'subscribe',

		search: 'search',
		searchHot: 'Hot Search',
		
		coupons: 'coupons',
		myCoupons: 'My coupons',
		reach: 'reach',
		available: 'available',
		get: 'Get',
		more: 'more',
		cart: 'Cart',
		myCart: 'My cart',
		myCartEmpty: 'Your shopping cart is empty.',
		latest: 'LATEST ARTICLES',
		store: 'HuarenShop Online Shopping Mall',
		storead: 'Australian Asian Selected Electrical City',
		xiaochengxu: 'Mobile terminal',
		postage: 'Free shipping for the whole site on Autumn Sale!',
		guarantee: 'Support 7 days no-reason return*, local after-sales service in Australia, one-year warranty for product quality problems (non-artificial), only exchange but not repair (only HuarenShop self-operated products)',
		credit_card: 'Credit Card', 
		we_pay: 'WeChat Pay',
		alipay: 'Alipay',
		shopping_cart: 'Newly added products', 
		delete: 'Delete',
		edit: 'Edit',
		cart_summary1: '',
		cart_summary: ' items, total amount',
		view_cart: 'View Cart',
		select_product: 'Selected products',
		settlement: 'CheckOut',

		//
		member: 'Member Center',
		welcome: 'Welcome',
		info: 'Personal details',
		security: 'Account security',
		deliveryAddress: 'Delivery address',
		collection: 'Collection',
		footprint: 'My Tracks',
		trading: 'Trading center ',
		myorder: 'My order',
		refund: 'Order afters',
		verification: 'verification',
		verificationList: 'verificationList',
		account: 'Account',
		money: 'Money',
		record: 'Record',
		coupon: 'Cupon',
		score: 'Score',
		acclist: 'Account list',
		cancel: 'Cancel',
		confirm: 'Confirm',
		prompt_info: 'Prompt information',
		delete_success: 'successfully deleted',
		self_support: 'self-support', 
		piece: 'piece',
		mobile: 'Please enter the mobile',
		email_error: 'Email address format error',
		payDynacodeText: 'Get dynamic code',
		reacquire: 'can be retrieved later',
		vercode: 'Please enter verification code',
		email_dynacode: 'Please enter email dynamic code',
		confirm_password: 'Please enter confirm password',
		password_twice: 'Inconsistent password entered twice',
		pwd_len_one: 'Password length cannot be less than',
		pwd_len_two: 'digits',
		pwd_complexity: 'Password must contain',
		numbers: 'numbers',
		lower_case_letters: ',lower case letters',
		uppercase_letter: ',uppercase letter',
		special_characters: ',special characters',
		repeatedly: 'Do not click repeatedly',
		evaluate_num: 'reviews',
		payment_num: " pay",
		limit_discount: 'Limit Discounts',
		postage_title: 'Postage:',
		delivery_title: 'Delivery:',
		guarantee_title: 'Guarantee:',
		payment_title: 'Payment method:',
		price_guarantee_title: 'Price guarantee：',
		price_guarantee: 'Lowest Price Guarantee for 15 days*',
		prevtext: 'Previous page',
		nexttext: 'Next page',

		category: 'Category',
		brand: 'Brand',
		all_brand: 'All brand',
		comprehensive: 'Comprehensive',
		sales: 'Sales',
		price: 'Price',
		fast: 'FAST',
		lowest_price: 'Lowest price',
		highest_price: 'Highest price',
		previous_page: 'Previous page',
		next_page: 'Next page',
		no_goods: 'The item you were looking for was not found. Try another condition',
		gotop: 'TOP',
	}
}
