export const lang = {
	//title为每个页面的标题
	title: '待支付',
	payment_text: '請選擇支付方式進行付款！',
	amount_payable: '應付金額',
	order_information: '訂單資訊',
	transaction_no: '交易單號',
	order_content: '訂單內容',
	order_amount: '訂單金額',
	create_time: '創建時間',
	payment_method: '支付方式',
	transfer_one: '轉帳時請使用【下單郵箱地址+下單連絡人姓名】作為付款備註,以便我們第一時間對您的轉帳進行核對。',
	transfer_two: '直接轉帳對應訂單金額到下方銀行帳戶,我們會在資金到賬後配送訂單。',
	transfer_three: '轉帳完成後,請務必點擊下方“完成訂單”按鈕！',
	transfer_four: '如您有任何疑問可聯系商城客服',
	confirm_pay: '請確認支付是否完成',
	confirm_pay_c: '完成支付前請根據您的情况點擊下麵的按鈕',
	choose_pay: '選擇其他支付方式',
	payment_completed: '已完成支付',
	credit_cards: '信用卡支付',
	credit_cards_trip: '交易經過雙重SSL安全加密,我們不存儲您的任何資訊。',
	wechat_pay: '微信支付',
	alipay: '支付寶支付',
	paypal: 'PayPal支付',
	eft_bank: 'EFT銀行轉帳',
	pay_button: '立即支付',
	complete_order: '完成訂單',
	payment_failed: '支付失敗',
	payment_failed_trip: '當前支付方式不可用或失敗,請選擇其他支付方式',
}
