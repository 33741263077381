	<template>
    <div class="footer">
		<!-- 售后保障 -->
        <div class="footer-top" v-if="shopServiceList.length > 0">
            <ul class="service">
                <li v-for="(item, index) in shopServiceList" :key="index">
					<div class="service-left">
						<el-image :src="$img(item.icon['imageUrl'])" :alt="item.name" fit="cover" />
					</div>
					<div class="service-right">
						<p>{{ item.service_name }}</p>
						<p class="desc">{{ item.desc }}</p>
					</div>
                </li>
            </ul>
        </div>

        <div class="footer-bot" style="border-bottom: 1px solid #FFFFFF;">
			<div class="left-box">
				<img :src="logo_big" >
				<p class="title">{{$lang("common.store")}}</p>
				<p><i class="iconfont icon-shouji" style="width:15px;text-align:center;margin-right:4px;"></i>00852-56255080（09:00-19:00）</p>
				<p>Only available from Monday to Friday</p>
				<p><i class="iconfont icon-youxiang1" style="width:15px;text-align:center;margin-right:4px;"></i>Email: online@huarenshop.com </p>
			</div>
			
			<div class="center" v-if="helpList.length">
				<div class="help" v-for="(item, index) in helpList" :key="index">
					<!-- {{item}} -->
					<p>
						<router-link :to="'/help/listother/' + item.class_id" target="_blank" :title="item.class_name">{{ item.class_name }}</router-link>
					</p>
					<ul class="help-ul">
						<li class="help-li" v-for="(helpItem, helpIndex) in item.child_list" :key="helpIndex">
							<router-link :to="'/help/item/' + helpItem.id" target="_blank" :title="helpItem.title">{{ helpItem.title }}</router-link>
						</li>
					</ul>
				</div>
			</div>

			<div class="news-box">
				<p class="title" @click="detil('list')">{{$lang("common.latest")}}</p>
				<ul>
					<li v-for="(item,index) in noticeList" :key="item.id">
						<p class="content">
							<router-link :to="'/notice/item/' + item.id" target="_blank" :title="item.title">{{ item.title }}</router-link>
						</p>
						<p class="time">{{ $util.timeStampTurnTime(item.create_time).substr(0,10) }}</p>
						<p class="fenge" v-if="index==0"></p>
					</li>
				</ul>
			</div>
		</div>
		<div class="friendly-link" v-if="linkList.length > 0">
			<dl>
				<dt>友情链接：</dt>
				<dd v-for="(link_item, link_index) in linkList" :key="link_index">
					<a :href="link_item.link_url" :title="link_item.link_title">{{ link_item.link_title }}</a>
				</dd>
			</dl>
		</div>
		<div class="footer-fot">
			2023 COPYRIGHT HUARENSHOP LIMITED.
			<img src="../../assets/images/payments.png" >
			<!-- <copy-right></copy-right> -->
		</div>
    </div>
</template>

<script>
	import {copyRight,shopServiceLists,friendlyLink,weQrcode,websiteInfo} from "@/api/website"
	import CopyRight from "./CopyRight"
	import {mapGetters} from "vuex"
	import {helpList} from "@/api/cms/help"
	import { noticesList } from "@/api/cms/notice"
	import Config from "@/utils/config.js"

	export default {
		props: {},
		data() {
			return {
				shopServiceList: [],
				linkList: [],
				helpList: [],
				noticeList: [],
				ishide: false,
				activeName: "first",
                qrcode:"",
                websiteInfo:"",
				logo_big: Config.imgDomain + '/public/static/img/logo_big.png'			}
		},
		computed: {
			...mapGetters(["siteInfo"])
		},
		created() {
			this.getShopServiceLists(),
			this.link(),
			this.getHelpList(),
            this.getqrcodeimg(),
            this.getWebSiteInfo(),
	    	this.getNotice()
		},
		mounted() {},
		watch: {},
		methods: {
		    getqrcodeimg(){
                weQrcode({}).then(res => {
					if (res.code == 0 && res.data) {
						this.qrcode = res.data
					}
				}).catch(err => {
					this.$message.error(err.message)
				})
            },
			getShopServiceLists() {
				shopServiceLists({}).then(res => {
					if (res.code == 0 && res.data) {
						this.shopServiceList = res.data
					}
				}).catch(err => {
					this.shopServiceList = []
				})
			},
			link() {
				friendlyLink({}).then(res => {
					if (res.code == 0 && res.data) {
						this.linkList = res.data
					}
				}).catch(err => {
					this.$message.error(err.message)
				})
			},
			linkUrl(url, target) {
				if (!url) return
				if (url.indexOf("http") == -1) {
					if (target) this.$router.pushToTab({
						path: url
					})
					else this.$router.push({
						path: url
					})
				} else {
					if (target) window.open(url)
					else window.location.href = url
				}
            },
			getNotice() {
				noticesList({
					page: 1,
					page_size: 5,
					receiving_type: "web"
				}).then(res => {
					this.noticeList = res.data.list
				}).catch(err => err)
			},
			/**
			 * 获取帮助列表
			 */
			getHelpList() {
				helpList().then(res => {
					if (res.code == 0 && res.data) {
						var arr = [];
						arr = res.data.slice(0, 4)
						for (let i = 0; i < arr.length; i++) {
							arr[i].child_list = arr[i].child_list.slice(0, 8);
						}

						this.helpList = arr
					}
				}).catch(err => {})
            },
            //底部电话等信息 
            getWebSiteInfo(){
                websiteInfo({}).then(res => {
					this.websiteInfo = res.data;
				}).catch(err => {})
            },
			/**
			 * 图片加载失败
			 */
			imageError() {
				this.siteInfo.web_qrcode = "../../assets/images/wxewm.png"
			},
			/**
			 * 跳转到帮助列表
			 */
			clickToHelp(id) {
				this.$router.push("/help/listother/" + id)
			},
			/**
			 * 跳转到帮助详情
			 */
			clickToHelpDetail(id) {
				this.$router.push("/help/item/" + id)
			},
			/**
			 * 跳转到帮助详情
			 */
			clickJump(address) {
				location.href= address;
			}
		},
		components: {
			CopyRight
		}
	}
</script>

<style scoped lang="scss">
    .footer {
        .footer-top {
            background-color: #fff;
            .service {
                margin: 0;
                padding: 0;
                width: $width;
                margin: 0 auto;
                padding: 30px 0;
                box-sizing: border-box;
                border-bottom: 1px solid #e9e9e9;
                display: flex;
                justify-content: space-around;
                align-items: center;
                li {
                    list-style: none;
                    /*line-height: 50px;*/
                    text-align: center;
                    flex: 1;
					display: inline-block;
					.service-left{
						display: inline-block;
						width: 70px;
						height: 70px;
						line-height: 70px;
						img {
							width: 100%;
							height: 100%;
						}
					}
					.service-right {
						display: inline-block;
						width: 200px;
						height: 70px;
						vertical-align: bottom;
						text-align: left;
						padding-left: 10px;
						p {
							font-size: 16px;
							line-height: 20px;
							margin-top: 6px;
							font-weight: 600;
							color: #2d2a2a;
						}
		
						.desc {
							margin-top: 2px;
							font-size: 12px;
							color: #777;
						}
					}
                }
            }
        }

        .footer-center {
            width: $width;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            padding: 50px 0;
            .left {
                width: 300px;

                .left-phone {
                    font-size: 26px;
                    font-weight: 600;
                    color: $base-color;
                    padding-bottom: 10px;
                    border-bottom: 1px solid $base-color;
                    margin-bottom: 12px;
                }
                .left-email {
                    font-size: 18px;
                    color: #838383;
                }
            }
            .center {
                width: calc(100% - 550px);
                padding: 0 68px;
                display: flex;
                justify-content: space-between;

                p {
                    font-size: 18px;
                    font-weight: 600;
                    color: #838383;
                    margin-bottom: 10px;
                    cursor: pointer;
                }

                .help-li {
                    font-size: 14px;
                    color: #838383;
                    line-height: 30px;
                    cursor: pointer;
                }
                .help-phone{
                    font-size: 15px;
                    color: #838383
                }

                p:hover, .help-li:hover {
                    color: $base-color;
                }
            }
            .right {
                width: 250px;
                text-align: center;

                .el-image {
                    width: 120px;
                    height: 120px;
                    line-height: 120px;
                    text-align: center;

                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }

                p {
                    font-size: 18px;
                    color: #838383;
                }
            }

            .qrcode-hide {
                display: none;
            }
        }

        .footer-bot {
            background: #FFF;
            color: #9D9D9D;
			width: $width;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
			padding: 50px 100px;
			.left-box {
				color: #777;
			    width: 415px;
				// padding-right: 60px;
				
				img {
					width: 100%;
					padding-bottom: 10px;
				}
				.title{
					color: #444444;
					font-weight: 700;
				}
				.desc{
					padding-bottom: 20px;
				}
			
			    .left-phone {
			        font-size: 26px;
			        font-weight: 600;
			        // color: $base-color;
			        padding-bottom: 10px;
			        // border-bottom: 1px solid $base-color;
					border-bottom: 1px solid #FFFFFF;
			        margin-bottom: 12px;
			    }
			    .left-email {
			        font-size: 18px;
			        color: #838383;
			    }
			}
			
			.news-box{
				width: 350px;
				padding: 0 30px;
				display: flex;
				// justify-content: space-between;
				flex-direction: column;
				
				.fenge{
					border-bottom: 1px solid #ddd;
					padding:10px 0;
				}
				
				li{
					padding: 10px 0;
				}
				
				.title{
					color: #838383;
					margin-bottom: 10px;
					font-size: 18px;
					font-weight: 600;
					cursor: pointer;
					&:hover{
						color: $base-color;
					}
				}
				
				.content{
					color: #555;
					padding-bottom: 10px;
					cursor: pointer;
					&:hover{
						color: $base-color;
					}
				}
				.time{
					font-size: 12px;
				}
			}
			.center {
			    width: calc(100% - 550px);
			    padding: 0 30px;
			    display: flex;
			    justify-content: space-between;
			
			    p {
			        font-size: 18px;
			        font-weight: 600;
			        color: #838383;
			        margin-bottom: 10px;
			        cursor: pointer;
			    }
			
			    .help-li {
			        font-size: 14px;
			        color: #838383;
			        line-height: 30px;
			        cursor: pointer;
			    }
			
			    p:hover, .help-li:hover {
			        color: $base-color;
			    }
			}
			.right {
			    width: 250px;
			    text-align: center;
			
			    .el-image {
			        width: 120px;
			        height: 120px;
			        line-height: 120px;
			        text-align: center;
					background-color: #FFFFFF;
			    }
			
			    p {
			        font-size: 18px;
			        color: #838383;
			    }
			}
			
			.qrcode-hide {
			    display: none;
			}
        }
		
		.footer-fot {
			// background: #000;
			color: #9D9D9D;
			width: $width;
			height: 60px;
			margin: 0 auto;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0px;
			border-top: 1px solid #DDD;
			
			img{
				height: 20px;
			}
		}
		
        .friendly-link {
            width: $width;
            margin: 0 auto;
            //border: 1px solid #e9e9e9;
			dl {
				width: 100%;
				display: flex;
				line-height: 40px;
				dt {
					width: 80px;
				}
				dd {
					padding: 0px 10px 0px 0px;
				}
			}

            .link-title {
                line-height: 30px;
                padding: 10px 0 5px;
                margin: 0px 0 15px;
                border-bottom: 1px solid #e8e8e8;
            }
            .link-item {
                width: 10%;
                height: 50px;
                line-height: 47px;
                float: left;
                text-align: center;
                overflow: hidden;
                margin: 0 6px 10px 6px;
                -webkit-transition: opacity 0.3s, box-shadow 0.3s;
                transition: opacity 0.3s, box-shadow 0.3s;
                border: 1px solid #fff;
                border-left: none;
                cursor: pointer;
            }
            .link-item:hover {
                width: -webkit-calc(10% + 1px);
                width: calc(10% + 1px);
                margin-left: 4px;
                position: relative;
                opacity: 1;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                border: 1px solid #dddddd;
            }
            .clear {
                clear: both;
            }
        }
    }
</style>
<style lang="scss">
    .friendly-link {
        .el-tabs__nav-scroll {
            padding-left: 30px;
            height: 50px;
            line-height: 50px;
        }
        .el-tabs__content {
            padding: 0 20px;
        }
        .el-tabs__nav-wrap::after {
            height: 1px;
        }
        .el-tabs__nav {
            padding: 0 20px;
        }
        .el-tabs__active-bar {
            padding: 0 20px;
        }
    }
</style>
