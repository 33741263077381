export const lang = {
	//title为每个页面的标题
	title: '訂單詳情',
	order_list: '訂單清單',
	order_status: '訂單狀態',
	comment: '評估',
	review: '追評',
	order_info: '訂單資訊',
	shop: '店鋪',
	order_type: '訂單類型',
	order_number: '訂單編號',
	order_transaction:'訂單交易號',
	distribution: '配送方式',
	create_time: '創建時間',
	payment: '支付方式',
	payment_time: '支付時間',
	store_activities: '店鋪活動',
	buyer_message: '買家留言',
	receiving_info:'收貨資訊',
	consignee: '收貨人',
	phone_number:'手機號碼',
	zip_code: '郵編',
	receiving_address: '收貨地址',
	product_info: '商品資訊',
	unit_price: '單價',
	quantity: '數量',
	subtotal: '小計',
	order_status: '訂單狀態',
	operation: '操作',
	closing_time: '關閉時間',
	payment_required: '需付款',
	refund: '退款',
	view_refund: '查看退款',
	apply_service: '申請售後',
	view_service: '查看售後',
	product_amount: '商品金額',
	freight: '運費',
	taxation: '稅費',
	order_adjustment: '訂單調整',
	discount: '優惠',
	coupon_amount: '優惠券金額',
	platform_coupon_amount: '平臺優惠券金額',
	use_balance: '使用餘額',
	actual_payment: '實付款',
	no_order: '未獲取到訂單資訊',
	discountcode: '折扣碼',
}
