export const lang = {
	//title为每个页面的标题
	title: 'My coupon',
	shop_coupon: 'Shop coupon',
	platform_coupon: 'Platform coupon',
	previous_page: 'Previous page',
	next_page: 'Next page',
	no_coupon: 'No coupon',
	no_threshold_coupon: 'No threshold coupon',
	full: 'Full',
	available: 'available',
	valid_until: 'Valid until',
	validity_one: 'Valid for ',
	validity_two: 'days from the date of collection',
	receive: '立即领取',
	to_use: 'To use',
	unused: 'Unused',
	used: 'Used',
	expired: 'Expired',
}
