export const lang = {
	//title为每个页面的标题
	title: '商品詳情',
	details: '商品詳情',
	attribute: '商品内容',
	reviews: '商品評估',
	buynow: '立即搶購',
	all_review: '全部評估',
	out_stock: '庫存不足',
	quantity: '數量',
	stock: '庫存',
	stock_1: '件',
	off_shelf: '該商品已下架',
	focus_goods: '關注商品',
	evaluate: '累計評估',
	sales: '累計銷量',
	brand: '品牌',
	o_price: '原價',
	price_spike: '秒殺價',
	limited_spike: '限時秒殺',
	seckill_end: '距離結束僅剩',
}
