export const lang = {
	//title为每个页面的标题
	title: 'Shipping address',
	add_address: 'Add shipping address',
	default: 'default',
	edit: 'Edit',
	set_default: 'Set default',
	delete: 'Delete',
	tips: 'Tips',
	confirm_tips: 'Are you sure you want to delete this address?',
	confirm: 'Confirm',
	cancel: 'Cancel',
	delete_msg: 'Default address, cannot be deleted!',
}
