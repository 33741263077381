export const lang = {
	//title为每个页面的标题
	title: '待支付',
	payment_text: '请选择支付方式进行付款！',
	amount_payable: '应付金额',
	order_information: '订单信息',
	transaction_no: '交易单号',
	order_content: '订单内容',
	order_amount: '订单金额',
	create_time: '创建时间',
	payment_method: '支付方式',
	transfer_one: '转账时请使用【下单邮箱地址 + 下单联系人姓名】作为付款备注，以便我们第一时间对您的转账进行核对。',
	transfer_two: '直接转账对应订单金额到下方银行账户，我们会在资金到账后配送订单。',
	transfer_three: '转账完成后，请务必点击下方“完成订单”按钮！',
	transfer_four: '如您有任何疑问可联系商城客服',
	confirm_pay: '请确认支付是否完成',
	confirm_pay_c: '完成支付前请根据您的情况点击下面的按钮',
	choose_pay: '选择其他支付方式',
	payment_completed: '已完成支付',
	credit_cards: '信用卡支付',
	credit_cards_trip: '交易经过双重SSL安全加密，我们不存储您的任何信息。',
	wechat_pay: '微信支付',
	alipay: '支付宝支付',
	paypal: 'PayPal支付',
	eft_bank: 'EFT 银行转账',
	pay_button: '立即支付',
	complete_order: '完成订单',
	payment_failed: '支付失败',
	payment_failed_trip: '当前支付方式不可用或失败，请选择其他支付方式',
}
