export const lang = {
	//title为每个页面的标题
	title: '秒杀专区',
	limited_spike: '限时秒杀',
	seckill_end: '距离结束仅剩',
	activity_ended: '活动已结束',
	snap_up: '抢购中',
	about_start: '即将开始',
	forward_to: '敬请期待',
	over: '已结束',
	buy_now: '立即抢购',
	previous_page: '上一页',
	next_page: '下一页',
	no_seckill: '暂无正在进行秒杀的商品，去首页看看吧',
	spike_ended: '秒杀已结束',
	activity_not_open: '秒杀活动还未开启，敬请期待！',
	price_spike: '秒杀价',
}
