export const lang = {
	//title为每个页面的标题
	title: 'Ticket collection center',
	coupon_center: 'Coupon Center',
	coupon_trip: 'Save more money and get more coupons',
	limited_coupon: 'grab coupon',
	stacked_use: 'stacked use',
	variety: 'variety',
	my_coupon: 'My coupon',
	receive: '立即领取',
	to_use: 'To use',
	previous: 'previous page',
	next: 'next page',
	no_coupon: 'No coupon',
	no_threshold_coupon: 'No threshold coupon',
	full: 'Full',
	available: 'available',
	valid_until: 'Valid until',
	validity_one: 'Valid for ',
	validity_two: 'days from the date of collection',
}
