export const lang = {
	//title为每个页面的标题
	title: 'Commodity Details',
	details: 'Commodity Details',
	attribute: 'Commodity Attribute',
	reviews: 'Reviews',
	buynow: 'Buy Now',
	all_review: 'All Reviews',
	out_stock: 'Out of stock',
	quantity: 'Quantity',
	stock: 'Inventory ',
	stock_1: '',
	off_shelf: 'Off shelf',
	focus_goods: ' Add to wishlist',
	evaluate: 'Evaluate',
	sales: 'Sales',
	brand: 'Brand',
	o_price: 'Original price',
	price_spike: 'Price spike',
	limited_spike: 'Limited time spike',
	seckill_end: 'Distance End',
}
