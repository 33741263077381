export const lang = {
	//title为每个页面的标题
	title:'購物車',
	goods_item:'商品資訊',
	price:'價格',
	quantity:'數量',
	subtotal:'小計',
	operation:'操作',
	select_all:'全選',
	clear_cart:'清除失效寶貝',
	select_items:'已選商品',
	total_amount:'合計（不含運費）',
	empty_cart:'您的購物車是空的,趕快去逛逛,挑選商品吧！',
	piece:'件',
	delete_trip:'確定要删除該商品嗎？',
	delete_select_trip:'確定要删除選擇的商品嗎？',
	select_delete_goods:'請選擇要删除的商品',
	clear_trip:'確認要清空這些商品嗎？',
	expired_goods:'失效商品',
}
