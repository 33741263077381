export const lang = {
	//title为每个页面的标题
	title: '商品列表',
	home: '首页',
	category: '分类',
	brand: '品牌',
	all_brand: '所有品牌',
	comprehensive: '综合',
	sales: '销量',
	price: '价格',
	fast: '自营',
	lowest_price: '最低价格',
	highest_price: '最高价格',
	previous_page: '上一页',
	next_page: '下一页',
	no_goods: '没有找到您想要的商品。换个条件试试吧'

}
