export const lang = {
	//title为每个页面的标题
	title: '支付结果',
	transfer_status: '待审核订单',
	payment_success: '支付成功',
	payment_failed: '支付失败',
	payment_amount: '支付金额',
	transaction_no: '交易单号',
	transfer_one: '转账时请使用【下单邮箱地址 + 下单联系人姓名】作为付款备注，以便我们第一时间对您的转账进行核对。',
	transfer_two: '直接转账对应订单金额到下方银行账户，我们会在资金到账后配送订单。',
	transfer_three: '订单已转入待审核状态 资金核对完毕后此订单状态会转换为待发货',
	transfer_four: '如您有任何疑问可联系商城客服',
	choose_pay: '选择其他支付方式',
	view_order: '查看订单',
	to_home: '回到首页',

}
