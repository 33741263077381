export const lang = {
	title: 'Cart',
	goods_item: 'Item',
	price: 'Price',
	quantity: 'Quantity',
	subtotal: 'Subtotal',
	operation: 'Operation',
	select_all: 'Select All',
	clear_cart: 'Clear invalid goods',
	select_items: 'Selected items', 
	total_amount: 'Total (excluding freight)',
	empty_cart: 'Your shopping cart is empty. Go shopping and pick up the goods!',
	piece: 'piece',
	delete_trip: 'Are you sure you want to delete this item?',
	delete_select_trip: 'Are you sure you want to delete the selected items?',
	select_delete_goods: 'Please select the item to delete',
	clear_trip: 'Are you sure you want to empty these products?',
	expired_goods: 'Expired Goods',
}
