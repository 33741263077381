export const lang = {
	//title为每个页面的标题
	title: '秒殺專區',
	limited_spike: '限時秒殺',
	seckill_end: '距離結束僅剩',
	activity_ended: '活動已結束',
	snap_up: '搶購中',
	about_start: '即將開始',
	forward_to: '敬請期待',
	over: '已結束',
	buy_now: '立即搶購',
	previous_page: '上一頁',
	next_page: '下一頁',
	no_seckill: '暫無正在進行秒殺的商品,去首頁看看吧',
	spike_ended: '秒殺已結束',
	activity_not_open: '秒殺活動還未開啟,敬請期待！',
	price_spike: '秒殺價', 
}
