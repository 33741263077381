export const lang = {
	//title为每个页面的标题
	title: '支付结果',
	order_list: '订单列表',
	order_status: '订单状态', 
	comment: '评价',
	review: '追评',
	order_info: '订单信息',
	shop: '店铺',
	order_type: '订单类型',
	order_number: '订单编号',
	order_transaction:'订单交易号',
	distribution: '配送方式',
	create_time: '创建时间',
	payment: '支付方式',
	payment_time: '支付时间',
	store_activities: '店铺活动',
	buyer_message: '买家留言',
	receiving_info:'收货信息',
	contact_info:'联系信息',
	consignee: '收货人',
	phone_number:'手机号码',
	zip_code: '邮编',
	receiving_address: '收货地址',
	product_info: '商品信息',
	unit_price: '单价',
	quantity: '数量',
	subtotal: '小计',
	order_status: '订单状态', 
	operation: '操作',
	closing_time: '关闭时间',
	payment_required: '需付款',
	refund: '退款',
	view_refund: '查看退款', 
	apply_service: '申请售后',
	view_service: '查看售后',
	product_amount : '商品金额',
	freight: '运费',
	taxation: '税费',
	order_adjustment: '订单调整',
	discount: '优惠',
	coupon_amount: '优惠券金额',
	platform_coupon_amount: '平台优惠券金额' ,
	use_balance: '使用余额', 
	actual_payment: '实付款',
	no_order: '未获取到订单信息',
	email_title: '邮箱地址',
}
