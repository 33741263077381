export const lang = {
	//title为每个页面的标题
	title: 'Payment result',
	transfer_status: 'pending order',
	payment_success: 'payment successful',
	payment_failed: 'payment failed',
	payment_amount: 'payment amount',
	transaction_no: 'Transaction no',
	transfer_one: 'Use the bank time code [sixth place after trading number + lower single address + lower South Korean name]',
	transfer_two: 'Directly apply for single payment to the lower bank account, we will meet the payment after delivery.',
	transfer_three: 'The order has been transferred to the pending approval status. After the fund check, the order status will be changed to the pending shipment status',
	transfer_four: 'If you have any questions, please contact the store customer service',
	choose_pay: 'Choose another payment method',
	view_order: 'View order',
	to_home: 'Back to home',
}
