export const lang = {
	//title为每个页面的标题
	title:'華人嚴選（Huarenshop）',
	login:'您好,請登入',
	register:'免費註冊',
	logout:'退出',
	myOrder:'我的訂單',
	myHistory:'我的足迹',
	myAttention:'我的關注',
	help:'幫助中心',
	notice:'公告',
	mobile:'手機商城',
	limited_spike:'限時秒殺',
	seckill_end:'距離結束',
	activity_ended:'活動已結束' 
}
