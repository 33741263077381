export const lang = {
	//title为每个页面的标题
	title: 'Personal data',
	first_title: 'Basic info',
	second_title: 'Avatar photos',
	avatar_preview: 'avatar preview',
	avatar_content: 'Improving your personal information and uploading avatar pictures will help you make more friends. ',
	avatar_tips: 'The best default size of the avatar is 120x120 pixels. ',
	save: 'save',
	userName: 'Account',
	nickName: 'Nickname',
	email: 'Email',
	mobile: 'Mobile',
	binding: 'Debinding',
	nickename_tips: 'Please enter a nickname',
	nickename_format: 'The maximum length is 30 characters',
	success: 'Successfully modified nickname',
	avater_success: 'Avatar modified successfully',
}
