export const lang = {
	//title为每个页面的标题
	title: '會員中心',
	member: '會員中心',
	welcome: '歡迎頁',
	info: '個人資訊',
	security: '帳戶安全',
	deliveryAddress: '收貨地址',
	collection: '我的關注',
	footprint: '我的足迹',
	trading: '交易中心',
	myorder: '我的訂單',
	refund: '訂單售後',
	verification: '核銷臺',
	verificationList: '核銷記錄',
	account: '帳戶中心',
	money: '帳戶餘額',
	record: '提現記錄',
	coupon: '我的優惠券',
	score: '我的積分',
	acclist: '帳戶清單',

	notlog: '未登錄',
	grow: '成長值',
	coupons: '優惠券',
	scores: '積分',
	moneys: '餘額',

	collection2: '店鋪關注',
	collection3: '商品關注',
	details: '查看詳情',
	daifukuan: '待付款',
	daifahuo: '待發貨',
	daishouhuo: '待收貨',
	daipingjia: '待評估', 
}
