export const lang = {
	//title为每个页面的标题
	title: 'Order afters',
	product_info: 'product info',
	refund_amount: 'refund amount',
	refund_type: 'refund type',
	refund_status: 'refund status',
	operation: 'operation', 
	prevtext: 'previous page',
	nexttext: 'next page',
	refund_success: 'refund success', 
	refund_progress: 'refund in progress',
	refund: 'refund',
	return_goods: 'return goods',
	refund_details: 'refund details',
	no_data: 'No relevant orders',
	confirm_revoke: 'confirm revoke',
	no_revoke: 'no revoke',
	revoke_tips: 'After cancellation, the application will be closed. If there are still problems in the follow-up, the application can be initiated again',
	tips: 'tips',
	refund_no: 'refund number',
}