export const lang = {
	//title为每个页面的标题
	title: '华人严选(Huarenshop)',
	login:'您好，请登录',
	register:'免费注册',
	logout:'退出',
	myOrder:'我的订单',
	myHistory:'我的足迹',
	myAttention:'我的关注',
	help:'帮助中心',
	notice:'公告',
	mobile:'手机商城',
	limited_spike: '限时秒杀',
	seckill_end: '距离结束',
	activity_ended: '活动已结束'
}
