export const lang = {
	//title为每个页面的标题
	title: '订单售后',
	product_info: '商品信息',
	refund_amount: '退款金额',
	refund_type: '退款类型',
	refund_status: '退款状态',
	operation: '操作', 
	prevtext: '上一页',
	nexttext: '下一页',
	refund_success: '退款成功', 
	refund_progress: '退款中',
	refund: '退款',
	return_goods: '退货',
	refund_details: '退款详情',
	no_data: '暂无相关订单',
	confirm_revoke: '确定撤销',
	no_revoke: '暂不撤销',
	revoke_tips: '撤销之后本次申请将会关闭,如后续仍有问题可再次发起申请',
	tips: '提示',
	refund_no: '退款编号',
}