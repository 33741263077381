export const lang = {
	//title为每个页面的标题
	title:'找回密碼',
	process_one:'輸入電子郵箱',
	process_two:'重置密碼',
	process_three:'完成',
	email_trip:'請輸入注册的電子郵箱',
	next_step:'下一步',
	vercode_trip:'請輸入驗證碼',
	dynacode_trip:'請輸入郵箱動態碼',
	passwd_trip:'請輸入新的登入密碼',
	repasswd_trip:'請再次輸入新密碼',
	reset_password:'重置密碼',
	reset_success:'重置密碼成功',
	re_login:'重新登入', 
}
