export const lang = {
	//title为每个页面的标题
	title:'訂單售後',
	product_info:'商品資訊',
	refund_amount:'退款金額',
	refund_type:'售後類型',
	refund_status:'售後狀態',
	operation:'操作',
	prevtext:'上一頁',
	nexttext:'下一頁',
	refund_success:'售後完成',
	refund_progress:'處理中',
	refund:'退款',
	return_goods:'退貨',
	refund_details:'售後詳情',
	no_data:'暫無相關訂單',
	confirm_revoke:'確定撤銷',
	no_revoke:'暫不撤銷',
	revoke_tips:'撤銷之後本次申請將會關閉,如後續仍有問題可再次發起申請',
	tips:'提示',
	refund_no:'售後編號', 
}