export const lang = {
	//title为每个页面的标题
	title: '订单列表',
	my_order: '我的订单',
	all_orders: '全部订单',
	pending_payment: '待付款',
	delivered: '待发货',
	receipt: '待收货',
	comment: '待评价',
	product_info: '商品信息',
	unit_price: '单价',
	quantity: '数量',
	payment: '实付款',
	order_status: '订单状态', 
	operation: '操作',
	order_number: '订单号', 
	order_details: '订单详情', 
	comment: '评价',
	review: '追评',
	no_order: '暂无相关订单',
	prevtext: '上一页',
	nexttext: '下一页',
}
