export const lang = {
	//title为每个页面的标题
	title: '商品详情',
	details: '商品详情',
	attribute: '商品属性',
	reviews: '商品评价',
	buynow: '立即抢购',
	all_review: '全部评价',
	out_stock: '库存不足',
	quantity: '数量',
	stock: '库存',
	stock_1: '件',
	off_shelf: '该商品已下架',
	focus_goods: '关注商品',
	evaluate: '累计评价',
	sales: '累计销量',
	brand: '品牌',
	o_price: '原价',
	price_spike: '秒杀价',
	limited_spike: '限时秒杀',
	seckill_end: '距离结束仅剩',
}
