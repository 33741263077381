export const lang = {
	//title为每个页面的标题
	title: 'Retrieve password',
	process_one: 'Enter email',
	process_two: 'Reset password',
	process_three: 'Complete',
	email_trip: 'Please enter the registered email address',
	next_step: 'Next step',
	vercode_trip: 'Please enter verification code',
	dynacode_trip: 'Please enter email dynamic code',
	passwd_trip: 'Please enter a new login password',
	repasswd_trip: 'Please enter new password again',
	reset_password: 'Reset password',
	reset_success: 'Password reset successful',
	re_login: 'Re login',
}
