export const lang = {
	//title为每个页面的标题
	title: 'Login',
	welcome: 'Welcome to login',
	welcome_register: 'Welcome to register',
	label: 'Password login ',
	email_code: 'Mailbox dynamic code login',
	mobile_code: 'Mobile dynamic code login',
	account: 'Please enter the email',
	password: 'Please enter your login password',
	mobile: 'Please enter the mobile',
	vercode: 'verification code',
	autoLogin: '7 days automatic login',
	findPass: 'Forgot password?',
	noAccount: "There is no account?",
	register: 'Register now',
	submit: 'Login',
	login_success: 'Login success!'
}
