export const lang = {
	//title为每个页面的标题
	title: '待付款訂單',
	account: '請輸入登入郵箱',
	email_code: '郵箱動態碼登入',
	loginsubmit: '登入',
	login_success: '登入成功',
	bindsubmit: '綁定',
	goods_item: '商品資訊',
	price: '價格',
	quantity: '數量',
	subtotal: '小計',
	shipping_address: '收貨地址',
	goods_amount: '商品金額',
	freight: '運費',
	coupon: '優惠券',
	shop_coupon: '店鋪優惠券',
	platform_coupon: '平臺優惠券',
	use_balance: '使用餘額',
	discount: '優惠',
	shop_discount: '店鋪優惠',
	select_coupon: '選擇優惠券',
	select_platform_coupon: '選擇平臺優惠券',
	name: '名稱',
	use: '使用',
	deadline: '期限',
	cancel: '取消',
	confirm_select: '確認選擇',
	settlement: '訂單結算',
	amount_payable: '應付金額',
	add_shipping_address: '添加收貨地址',
	delete_address: '確定要删除該地址嗎？',
	more_address: '更多收貨地址',
	edit_shipping_address: '編輯收貨地址',
	email: '聯系資訊',
	email_trip: '請輸入聯系資訊',
	contact_trip: '請輸入聯系資訊',
	login: '登入',
	existing_account: '已有帳戶？',
	consignee: '收貨人',
	first_name_trip: '請輸入名（First name）',
	last_name_trip: '請輸入姓（Last name）',
	address: '詳細地址',
	address_trip: '請精確到樓棟門牌號',
	address_s_trip: '請輸入地址資訊',
	suburb: '區（Suburb）', 
	suburb_trip: '收貨人所在的區（Suburb）',
	country_state: '國家/州',
	select_country: '請選擇國家（Country）',
	select_state: '請選擇州（State）',
	post_code: '郵遞區號',
	post_code_trip: '請輸入正確的郵編',
	phone: '電話（Phone）',
	phone_trip: '請輸入收貨人手機號（Phone）,例如：0* **** ****',
	use_balance_or: '是否使用餘額',
	use_balance: '使用餘額',
	use_no_balance: '不使用餘額',
	gift: '贈品',
	delivery_method: '配送方式',
	no_select_address: '請先添加收貨地址',
	no_use_coupon: '不使用優惠券',
	use_coupon: '已選擇優惠券,已優惠',
	full_reduction: '滿减',
	full_amount_post: '滿額包郵',
	freeshipping: '包郵',
	full: '滿',
	buyer_message: '買家留言',
	message: '留言前建議先與商家協調一致',
	payable_amount: '件,應付金額',
	a_payable_amount: '共',
	available: '可用',
	all_available: '任意金額可用',
	order_submit: '訂單提交中…',
	select_shipping_address: '請先選擇您的收貨地址',
	discountcode: '折扣碼',
	discountcode_trip: '請輸入折扣碼', 
	discountcode_deduction: '折扣碼抵扣金額',
	use_point: '使用積分',
	use_no_point: '不使用積分',
	point_deduction: '積分抵扣金額',
}
