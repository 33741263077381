export const lang = {
	//title为每个页面的标题
	title: 'Seckill Zone',
	limited_spike: 'Limited time spike',
	seckill_end: 'Distance End',
	activity_ended: 'Activity Ended',
	snap_up: 'Snap -up',
	about_start: 'About to start',
	forward_to: 'Has not started',
	over: 'Over',
	buy_now: 'Buy now',
	previous_page: 'previous page',
	next_page: 'Next page',
	no_seckill: 'There is no product that is being carried out, go to the homepage to see',
	spike_ended: ' spike has ended',
	activity_not_open: 'The spike activity has not been opened yet, so stay tuned!',
	price_spike: 'Price spike',

}
