export const lang = {
	//title为每个页面的标题
	title: '购物车',
	goods_item: '商品信息',
	price: '价格',
	quantity: '数量',
	subtotal: '小计',
	operation: '操作',
	select_all: '全选',
	clear_cart: '清除失效宝贝',
	select_items: '已选商品', 
	total_amount: '合计（不含运费）',
	empty_cart: '您的购物车是空的，赶快去逛逛，挑选商品吧！',
	piece: '件',
	delete_trip: '确定要删除该商品吗？',
	delete_select_trip: '确定要删除选择的商品吗？',
	select_delete_goods: '请选择要删除的商品',
	clear_trip: '确认要清空这些商品吗？',
	expired_goods: '失效商品',
}
