export const lang = {
	//title为每个页面的标题
	title:'領券中心',
	coupon_center:'優惠券中心',
	coupon_trip:'省錢更多,領券更多',
	limited_coupon:'限時搶券',
	stacked_use:'疊加使用',
	variety:'種類多樣',
	my_coupon:'我的優惠券',
	receive:'立即領取',
	to_use:'去使用',
	previous:'上一頁',
	next:'下一頁',
	no_coupon:'暫無優惠券',
	no_threshold_coupon:'無門檻優惠券',
	full:'滿',
	available:'可使用',
	valid_until:'有效期至',
	validity_one:'領取之日起',
	validity_two:'日內有效',
}
