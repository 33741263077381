export const lang = {
	//title为每个页面的标题
	title: 'Order details',
	order_list: 'Order list',
	order_status: 'order status', 
	comment: 'comment',
	review: 'review',
	order_info: 'order info',
	shop: 'shop', 
	order_type: 'order type',
	order_number: 'order number',
	order_transaction: 'transaction no.',
	distribution: 'distribution',
	create_time: 'create time',
	payment: 'payment method',
	payment_time: 'payment time',
	store_activities: 'store activities',
	buyer_message: 'buyer message',
	receiving_info: 'receiving info',
	consignee: 'consignee',
	phone_number: 'phone number',
	zip_code: 'zip code',
	receiving_address: 'receiving address',
	product_info: 'product info',
	unit_price: 'unit price',
	quantity: 'quantity',
	subtotal: 'subtotal',
	order_status: 'status',
	operation: 'operation',
	closing_time: 'closing time',
	payment_required: 'payment required',
	refund: 'refund',
	view_refund: 'view refund', 
	apply_service: 'apply service',
	view_service: 'view service',
	product_amount: 'product amount',
	freight: 'freight',
	taxation: 'taxation',
	order_adjustment: 'order adjustment',
	discount: 'discount',
	coupon_amount: 'coupon amount',
	platform_coupon_amount: 'platform coupon amount',
	use_balance: 'use balance', 
	actual_payment: 'actual payment',
	no_order: 'Order information not obtained',
	discountcode: 'Discount code',
}
