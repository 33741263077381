export const lang = {
	//公共
	common: {
		//语言包名称
		name: '繁体中文',
		metaTitle: '全澳華人嚴選（Huarenshop）線上購物商城',
		login:'您好，請登入 ',
		register:'免費註冊',
		logout:'退出',
		myOrder:'我的訂單',
		myHistory:'我的足迹',
		myAttention:'我的關注',
		help:'幫助中心',
		notice:'公告',
		mobile_1:'手機商城',
		goods: "商品",
		shop: "店鋪",
		
		allCategory:'所有商品分類',
		
		//订阅
		subTitle:'加入我們獲取最新優惠資訊',
		subHint:'請輸入您的電子郵箱地址',
		subBtn:'訂閱',

		search:'蒐索',
		searchHot:'熱門搜索',

		coupons: '領券中心',
		myCoupons: '我的優惠券',
		reach: '滿',
		available: '可用',
		get: '立即獲取',
		more: '更多',
		cart: '購物車',
		myCart:'我的購物車',
		myCartEmpty:'您的購物車是空的,趕快去逛逛,挑選商品吧！',
		latest: '最新文章',
		store: ' 華人嚴選（HuarenShop）線上購物商城 ',
		storead: '全澳華人嚴選線上購物商城',
		xiaochengxu: '手機移動端',
		postage: '全澳限時無門檻包郵到家',
		guarantee: '支持七天無理由退換貨*,澳大利亞本地售後服務,產品品質問題享一年質保（非人為）只換不修（僅限HuarenShop自營產品）',
		credit_card: '信用卡',
		we_pay: '微信支付',
		alipay: '支付寶',
		shopping_cart: '最新加入的商品',
		delete: '删除',
		edit: '編輯',
		cart_summary1: '共',
		cart_summary: '種商品,總金額',
		view_cart: '去購物車',
		select_product: '商品精選',
		settlement: '結算',

		//
		member: '會員中心',
		welcome: '歡迎頁',
		info: '個人資訊',
		security: '帳戶安全',
		deliveryAddress: '收貨地址',
		collection: '我的關注',
		footprint: '我的足迹',
		trading: '交易中心',
		myorder: '我的訂單',
		refund: '訂單售後',
		verification: '核銷臺',
		verificationList: '核銷記錄',
		account: '帳戶中心',
		money: '帳戶餘額',
		record: '提現記錄',
		coupon: '我的優惠券',
		score: '我的積分',

		acclist:'帳戶清單',
		cancel:'取消',
		confirm:'確定',
		Aprompt_info:'提示資訊',
		delete_success:'删除成功',
		self_support:'自營',
		piece:'件',
		mobile:'請輸入手機號',
		email_error:'郵箱地址格式錯誤',
		payDynacodeText:'獲取動態碼',
		reacquire:'後可重新獲取',
		vercode:'請輸入驗證碼',
		email_dynacode:'請輸入郵箱動態碼',
		confirm_password:'請輸入確認密碼',
		password_twice:'兩次密碼輸入不一致',
		pwd_len_one:'密碼長度不能小於',
		pwd_len_two:'比特',
		pwd_complexity:'密碼需包含',
		numbers:'數位',
		lower_case_letters:'、小寫字母',
		uppercase_letter:'、大寫字母',
		special_characters:'、特殊字元',
		repeatedly:'請勿重複點擊',
		evaluate_num:'條評估',
		payment_num:'人付款',
		limit_discount:'限時折扣',
		postage_title:'郵費：',
		delivery_title:'配送：',
		guarantee_title:'保障：',
		payment_title:'支付方式：',
		price_guarantee_title:'價保：',
		price_guarantee:'15天內價格最低保障* ',
		prevtext:'上一頁',
		nexttext:'下一頁',

		category:'分類',
		brand:'品牌',
		all_brand:'所有品牌',
		comprehensive:'綜合',
		sales:'銷量',
		price:'價格',
		fast:'自營',
		lowest_price:'最低價格',
		highest_price:'最高價格',
		no_goods: '沒有找到您想要的商品。 換個條件試試吧',
		gotop: '返回頂部',
	}
}
