export const lang = {
	//title为每个页面的标题
	title: '会员中心',
	member: '会员中心',
	welcome: '欢迎页',
	info: '个人信息',
	security: '账户安全',
	deliveryAddress: '收货地址',
	collection: '我的关注',
	footprint: '我的足迹',
	trading: '交易中心',
	myorder: '我的订单',
	refund: '订单售后',
	verification: '核销台',
	verificationList: '核销记录',
	account: '账户中心',
	money: '账户余额',
	record: '提现记录',
	coupon: '我的优惠券',
	score: '我的积分',
	acclist: '账户列表',
	
	notlog: '未登录',
	grow: '成长值',
	coupons: '优惠券',
	scores: '积分',
	moneys: '余额',
	
	collection2: '店铺关注',
	collection3: '商品关注',
	details: '查看详情',
	daifukuan: '待付款',
	daifahuo: '待发货',
	daishouhuo: '待收货',
	daipingjia: '待评价',
	
}
