export const lang = {
	//title为每个页面的标题
	title: '支付結果',
	transfer_status: '待稽核訂單',
	payment_success: '支付成功',
	payment_failed: '支付失敗',
	payment_amount: '支付金額',
	transaction_no: '交易單號',
	transfer_one: '轉帳時請使用【下單郵箱地址+下單連絡人姓名】作為付款備註,以便我們第一時間對您的轉帳進行核對。',
	transfer_two: '直接轉帳對應訂單金額到下方銀行帳戶,我們會在資金到賬後配送訂單。',
	transfer_three: '訂單已轉入待稽核狀態資金核對完畢後此訂單狀態會轉換為待發貨',
	transfer_four: '如您有任何疑問可聯系商城客服',
	choose_pay: '選擇其他支付方式',
	view_order: '查看訂單',
	to_home: '回到首頁',
}
