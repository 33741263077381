export const lang = {
	//title为每个页面的标题
	title: 'Commodity Details',
	video: 'video',
	picture: 'picture',
	discount: 'Discount',
	day: 'day',
	hour: 'hour',
	minute: 'minute',
	seconds: 'seconds',
	s_price: 'Sales price',
	o_price: 'Original price',
	m_price: 'Vip price',
	mk_price: 'Market price',
	evaluate: 'Evaluate',
	sales: 'Sales',
	brand: 'Brand',
	coupon: 'Coupon',
	service: 'Service',
	details: 'Commodity Details',
	attribute: 'Commodity Attribute',
	reviews: 'Reviews',
	buynow: 'Buy Now',
	joincart: 'Add to cart',
	out_stock: 'Out of stock',
	quantity: 'Quantity',
	focus_goods: 'Add to wishlist',
	bundling: 'Combination package price',
	no_reviews: 'There is no comment on this product',
	all_review: 'All Reviews',
}
