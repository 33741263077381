export const lang = {
	//title为每个页面的标题
	title: 'Product List',
	home: 'Home',
	category: 'Category',
	brand: 'Brand',
	all_brand: 'All brand',
	comprehensive: 'Comprehensive',
	sales: 'Sales',
	price: 'Price',
	fast: 'FAST',
	lowest_price: 'Lowest price',
	highest_price: 'Highest price',
	previous_page: 'Previous page',
	next_page: 'Next page',
	no_goods: 'The item you were looking for was not found. Try another condition'
	

}
