export const lang = {
	//title为每个页面的标题
	title:'登入',
	welcome:'歡迎登錄',
	welcome_register:'歡迎註冊',
	label:'密碼登入',
	email_code:'郵箱動態碼登入',
	mobile_code:'簡訊動態碼登入',
	account:'請輸入登入郵箱',
	password:'請輸入登入密碼',
	mobile:'請輸入手機號',
	vercode:'請輸入驗證碼',
	autoLogin:'7天自動登錄',
	findPass:'忘記密碼？',
	noAccount:'沒有帳號？',
	register:'立即注册',
	submit:'登入',
	login_success:'登入成功！' 
}
