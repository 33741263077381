export const lang = {
	//title为每个页面的标题
	title:'注册',
	label:'用戶註冊',
	username:'密碼注册',
	emailRegister:'郵箱動態碼注册',
	mobileRegister:'手機動態碼注册',
	welcome:'歡迎註冊',
	account:'請輸入用戶名',
	password:'請輸入密碼',
	rePassword:'請輸入確認密碼',
	reNoPassword:'確認輸入密碼不一致！',
	email:'請輸入郵箱號',
	email_dynacode:'請輸入郵箱動態碼',
	payDynacodeText:'獲取動態碼',
	code_trip:'請輸入驗證碼',
	mobile_tips:'請輸入正確的手機號',
	mobile:'請輸入手機號',
	mobile_dynacode:'請輸入簡訊動態碼',
	button:'注册',
	login_button:'立即登入',
	read_agree:'閱讀並同意',
	aggrement:'《服務協議》',
	exist_account:'已有帳號,',
	read_agree_trip:'請先閱讀協定並勾選',
}
