export const lang = {
	//title为每个页面的标题
	title:'收貨地址',
	add_address:'添加收貨地址',
	default:'默認',
	edit:'編輯',
	set_default:'設為默認',
	delete:'删除',
	tips:'提示',
	confirm_tips:'確定要删除該地址嗎？',
	confirm:'確定',
	cancel:'取消',
	delete_msg:'默認地址,不能删除！',
}
