export const lang = {
	//title为每个页面的标题
	title: 'HuarenShop',
	metaTitle: 'Australian Asian Appliances Superstore',
	store: 'Australian Asian Appliances Superstore',
	login:'Login',
	register:'Register',
	logout:'Log out',
	myOrder:'My order',
	myHistory:'My history',
	myAttention:'My attention',
	help:'Help center',
	notice:'Notice',
	mobile:'Mobile store',
	limited_spike: 'Limited time spike',
	seckill_end: 'Distance End',
	activity_ended: 'Activity Ended',
}
