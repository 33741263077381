export const lang = {
	//title为每个页面的标题
	title: '收货地址',
	add_address: '添加收货地址',
	default: '默认',
	edit: '编辑',
	set_default: '设为默认',
	delete: '删除',
	tips: '提示',
	confirm_tips: '确定要删除该地址吗?',
	confirm: '确定',
	cancel: '取消',
	delete_msg: '默认地址，不能删除!',
}
