export const lang = {
	//title为每个页面的标题
	title: 'Pending Order',
	account: 'Please enter the email',
	email_code: 'Mailbox dynamic code login',
	loginsubmit: 'Login',
	login_success: 'Login Success',
	bindsubmit: 'Binding',
	goods_item: 'Item',
	price: 'Price',
	quantity: 'Quantity',
	subtotal: 'Subtotal',
	shipping_address: 'Shipping address',
	goods_amount: 'Product amount',
	freight: 'Freight',
	coupon: 'Coupon',
	shop_coupon: 'Shop Coupon',
	platform_coupon: 'Platform Coupon',
	use_balance: 'Use Balance',
	discount: 'Discount',
	shop_discount: 'Shop discount',
	select_coupon: 'Select Coupon',
	select_platform_coupon: 'Select Platform Coupon',
	name: 'Name',
	use: 'Use',
	deadline: 'Deadline',
	cancel: 'Cancel',
	confirm_select: 'Confirm Select',
	settlement: 'Order Settlement',
	amount_payable: 'Amount payable',
	add_shipping_address: 'Add shipping address',
	delete_address: 'Are you sure you want to delete this address?',
	more_address: 'More shipping addresses',
	edit_shipping_address: 'Edit shipping address',
	email: 'Email',
	email_trip: 'Please enter the email address',
	login: 'Login',
	existing_account: 'Existing account?',
	consignee: 'Name',
	first_name_trip: 'Please enter the first name',
	last_name_trip: 'Please enter the last name)',
	address: 'Address',
	address_trip: 'Please be accurate to the building door number',
	address_s_trip: 'Please enter the address information',
	suburb: 'Suburb',
	suburb_trip: "Please enter the consignee's area",
	country_state: 'Country/State',
	select_country: 'Please select a country',
	select_state: 'Please select a state',
	post_code: 'Postal Code',
	post_code_trip: 'Please enter the postal code',
	phone: 'Phone',
	phone_trip: "Please enter the receiver's phone number, for example: 0* **** ****",
	use_balance_or: 'Use balance or not',
	use_balance: 'Use balance',
	use_no_balance: 'Do not use balance',
	gift: 'gift',
	delivery_method: 'Delivery method',
	no_select_address: 'Please add the shipping address first',
	no_use_coupon: 'Do not use coupons',
	use_coupon: 'Coupon selected, discounted',
	full_reduction: 'Full reduction',
	full_amount_post: 'Full amount parcel post',
	freeshipping: 'free shipping',
	full: 'full',
	buyer_message: "Buyer's message",
	message: 'It is recommended to coordinate with the merchant before leaving a message',
	payable_amount: 'pieces, the amount payable',
	a_payable_amount: 'A total of',
	available: 'available',
	all_available: 'Any amount available',
	order_submit: 'Submitting order...',
	select_shipping_address: 'Please select your shipping address first',
	discountcode: 'Discount code',
	discountcode_trip: 'Please enter discount code',
	discountcode_deduction: 'Discount code deduction amount',
	use_point: 'Using points',
	use_no_point: 'Do not use points',
	point_deduction: 'Deduction amount of points',
}
