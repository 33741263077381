export const lang = {
	//title为每个页面的标题
	title: '编辑收货地址',
	first_name: '名(First name)',
	last_name: '姓(Last name)',
	first_placeholder: '拼音或英文名',
	last_placeholder: '拼音或英文姓',
	address: '详细地址(Address)',
	address_placeholder: '请精确到楼栋门牌号',
	city: '区(Suburb)',
	country: '国家(Country)',
	country_placeholder: '请选择国家(Country)',
	province_placeholder: '请选择州',
	post_code: '邮政编码(Post)',
	postcode_placeholder: '请输入正确的邮编',
	mobile: '电话(Phone)',
	mobile_placeholder: '请输入收货人手机号(Phone),例如：0* **** ****',
	is_default: '是否默认',
	save: '保存',
	yes: '是',
	no: '否',
	mobile_tips: '手机号不能为空',
	region_tips: '请选择地区',
	country_tips: '请选择国家',
	first_name_tips: '请输入收货人的名(First name)',
	last_name_tips: '请输入收货人的姓(Last name)',
	post_code_tips: '请输入邮编',
	address_tips: '请输入详细地址',
	phone_tips: '请输入电话号码(Phone),例如：0* **** ****',
}
