<template>
    <div id="app">
        <transition name="slide"><router-view /></transition>
    </div>
</template>
<script>
    export default {
        name: "APP",
        created() {
            if (this._isMobile()) {
				this.jumpMobileUrl();
			}
        },
        mounted() {
			
		},
        methods:{
            _isMobile() {
				let flag = navigator.userAgent.match(/(Googlebot|googlebot|Google|google)/i)
				let fmobile = navigator.userAgent.match(/(Googlebot-Mobile|googlebot-mobile|Googlebot-mobile|googlebot-Mobile)/i)
				if(flag && !fmobile) {
					return false;
				}
				flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
				return flag;
			},
            jumpMobileUrl() {
				let url = '';
				let pathname = window.location.pathname
				let u = new URL(window.location.href);
				//let search = window.location.href.search;
				let gclid = u.searchParams.get('gclid');
				let lang = u.searchParams.get('lang');

				// 团购和秒杀详情页
				if (pathname.match(/(groupbuy\/item|seckill\/item)/i)) {
					let path = pathname.split('\/')
					if(path[1] == 'groupbuy') {
						url = 'https://m.huarenshop.com/promotion/groupbuy/detail?groupbuy_id=' + path[3]
					} else if(path[1] == 'seckill') {
						url = 'https://m.huarenshop.com/promotion/seckill/detail?seckill_id=' + path[3]
					}
					if(gclid) url += "&gclid=" + gclid;
					if(lang) url += "&lang=" + lang;
					location.href = url;
					return true;
				}

				// 普通商品详情
				if (pathname.match(/(product-*)/i)) {
					let path = pathname.split('\-')
					if (path[0] == '/product' && path[1]) {
						path = path[1].split('\.')
						if (path[0]) {
							url = 'https://m.huarenshop.com/pages/goods/detail?sku_id=' + path[0]
						}
					}
					if(gclid) url += "&gclid=" + gclid;
					if(lang) url += "&lang=" + lang;
					location.href = url;
					return true;
				}
				
				if (pathname.match(/(list.html*)/i)) {
					let searchParams = window.location.search
					url = 'https://m.huarenshop.com/pages/goods/list?' + searchParams;
					if(gclid) url += "&gclid=" + gclid;
					if(lang) url += "&lang=" + lang;
					location.href = url;
					return true;
				}

				// 商品列表 
				if (pathname.match(/(list-*)/i)) {
					let path = pathname.split('\-')
					if (path[0] == '/list' && path[1]) {
						path = path[1].split('\.')
						if (path[0]) {
							url = 'https://m.huarenshop.com/pages/goods/list?category_id=' + path[0]
						}
					}
					if(gclid) url += "&gclid=" + gclid;
					if(lang) url += "&lang=" + lang;
					location.href = url;
					return true;
				}
				
				// 团购列表
				if (pathname.match(/(groupbuy)/i)) {
					url = 'https://m.huarenshop.com/groupbuy/groupbuy/list'
					if(gclid) {
						url += "?gclid=" + gclid;
						if(lang) url += "&lang=" + lang;
					} else {
						if(lang) url += "?lang=" + lang;
					}
					location.href = url;
					return true;
				}

				// 优惠 券
				if (pathname.match(/(coupon)/i)) {
					url = 'https://m.huarenshop.com/pages/index/index'
					if(gclid) {
						url += "?gclid=" + gclid;
						if(lang) url += "&lang=" + lang;
					} else {
						if(lang) url += "?lang=" + lang;
					}
					location.href = url;
					return true;
				}

				// 秒杀列表
				if (pathname.match(/(seckill)/i)) {
					url = 'https://m.huarenshop.com/groupbuy/seckill/list'
					if(gclid) {
						url += "?gclid=" + gclid;
						if(lang) url += "&lang=" + lang;
					} else {
						if(lang) url += "?lang=" + lang;
					}
					location.href = url;
					return true;
				}

				// 订单详情
				if (pathname.match(/(member\/order_detail)/i)) {
					let path = window.location.search.split('=')
					if (path[1]) {
						url = 'https://m.huarenshop.com/pages/order/detail?order_id=' + path[1]
						location.href = url;
						return true;
					}
				}

				// 订单支付
				if (pathname.match(/(pay)/i)) {
					let params = window.location.search.split('&')
					let code = '',authenticate = '';
					if (params[0]) {
						code = params[0].split('=')
					}

					if (params[1]) {
						authenticate = params[1].split('=')
					}
					
					if (code[1] && authenticate[1]) {
						url = 'https://m.huarenshop.com/tool/pay/order?code=' + code[1] + '&authenticate=' + authenticate[1]
						location.href = url;
						return true;
					}
				}

				if (pathname.match(/(result)/i)) {
					let path = window.location.search.split('=')
					if (path[1]) {
						url = 'https://m.huarenshop.com/tool/pay/result?code=' + path[1]
						location.href = url;
						return true;
					}
				}

				// 修改密码
				if (pathname.match(/(find_pass)/i)) {					
					if (window.location.search) {
						let path = window.location.search.split('=')
						if (path[1]) {
							url = 'https://m.huarenshop.com/tool/login/find?key=' + path[1]
							location.href = url;
							return true;
						}
					} else {
						url = 'https://m.huarenshop.com/tool/login/find'
						location.href = url;
						return true;
					}
				}

				let search = window.location.search

				if (search.match(/(suid)/i)) {
					url = 'https://m.huarenshop.com/pages/index/index' + search
					location.href = url;
					return true;
				} 
				
				url = 'https://m.huarenshop.com';
				if(gclid) {
					url += "?gclid=" + gclid;
					if(lang) url += "&lang=" + lang;
				} else {
					if(lang) url += "?lang=" + lang;
				}
				//if(gclid) url += "/pages/index/index?gclid=" + gclid;
				location.href = url;
			}
        }
    }
</script>
<style lang="scss" scoped>
    /*每个页面公共css */
    @import url("assets/styles/iconfont.css");
    @import url("assets/styles/icondiy.css");
    @import "assets/styles/main.scss";
</style>
