export const lang = {
	//title为每个页面的标题
	title: 'Order list',
	my_order: 'My Order',
	all_orders: 'all',
	pending_payment: 'payment',
	delivered: 'delivered',
	receipt: 'receipt',
	comment: 'comment',
	product_info: 'product info',
	unit_price: 'unit price',
	quantity: 'quantity',
	payment: 'payment',
	order_status: 'order status',
	operation: 'operation',
	order_number: 'order number', 
	order_details: 'order details',
	comment: 'Review',
	review: 'Follow up',
	no_order: 'No relevant orders',
	prevtext: 'previous page',
	nexttext: 'next page',
}
