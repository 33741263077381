export const lang = {
	//title为每个页面的标题
	title: 'Account security',
	login_passwd: 'Login password',
	login_passwd_trip: 'There is a risk of theft of your internet account. It is recommended that you change your password regularly to protect the security of your account',
	mailbox_bind: 'Mailbox binding',
	mailbox_bind_trip: 'The password can be retrieved quickly after login and payment',
	modify: 'modify',
	modify_passwd: 'Modify login password',
	old_passwd: 'Original password',
	new_passwd: 'New password',
	repeat_passwd: 'Confirm password',
	repeat_passwd_trip: 'Please confirm the new password',
	old_passwd_trip: 'Current password',
	new_passwd_trip: 'New password',
	save: 'save',
	confirm: 'Confirm',
	cancel: 'cancel',
	current_email: 'Current mailbox',
	email: 'Email',
	email_trip: 'Please enter email address',
	code: 'Verify Code',
	dynacode: 'Dynamic code',
	code_trip: 'Please enter the verify code',
	dynacode_trip: 'Please enter dynamic code',
	new_passwd_tips: 'Please enter a new password',
	new_repeat_tips: 'The new password cannot be the same as the original password!',
	repeat_passwd_tips: 'Please enter the password again',
	new_repeat_no_tips: 'The passwords entered twice do not match!',
	email_tips: 'Please enter correct email',
	mobile_tips: 'Please enter a valid phone number',
	old_tips: 'Please enter the original password',
	payCodeText: 'get verification code',
	mobile_code_trip: 'Enter SMS verification code',
	payDynacodeText: 'Get dynamic code',
	passwd_success: 'successfully change password',
	repeat_click: 'Do not click repeatedly',
	been_sent: 'Has been sent',
	email_success: 'successfully change email',
	tips_info: 'Tips',
	unbound_phone: "You haven't bound your phone number yet, please bind your phone number first?",
}