export const lang = {
	//title为每个页面的标题
	title: '注册',
	label: '用户注册',
	username: '密码注册',
	emailRegister: '邮箱动态码注册',
	mobileRegister: '手机动态码注册',
	welcome: '欢迎注册',
	account: '请输入用户名',
	password: '请输入密码',
	rePassword: '请输入确认密码',
	reNoPassword: '确认输入密码不一致!',
	email: '请输入邮箱号',
	email_dynacode:'请输入邮箱动态码',
	payDynacodeText: '获取动态码',
	code_trip: '请输入验证码',
	mobile_tips: '请输入正确的手机号',
	mobile: '请输入手机号',
	mobile_dynacode:'请输入短信动态码',
	button: '注册',
	login_button: '立即登录',
	read_agree: '阅读并同意', 
	aggrement: '《服务协议》',
	exist_account: '已有账号，', 
	read_agree_trip: '请先阅读协议并勾选',
}
