export const lang = {
	//title为每个页面的标题
	title: 'To be paid',
	payment_text: 'Please select a payment method to pay!',
	amount_payable: 'Amount payable',
	order_information: 'Order information',
	transaction_no: 'Transaction no',
	order_content: 'Order content',
	order_amount: 'Order amount',
	create_time: 'Creation time',
	payment_method: 'Payment method',
	transfer_one: 'Use the bank time code [sixth place after trading number + lower single address + lower South Korean name]',
	transfer_two: 'Directly apply for single payment to the lower bank account, we will meet the payment after delivery.',
	transfer_three: 'After the transfer is complete, be sure to click the "Complete Order" button below!',
	transfer_four: 'If you have any questions, please contact the store customer service',
	confirm_pay: 'Please confirm whether the payment is completed',
	confirm_pay_c: 'Please click the button below according to your situation before completing the payment',
	choose_pay: 'Choose another payment method',
	payment_completed: 'Payment completed',
	credit_cards: 'Credit Card',
	credit_cards_trip: 'The transaction is encrypted by double SSL security, and we do not store any of your information.',
	wechat_pay: 'Wechat pay',
	paypal: 'PayPal',
	alipay: 'Alipay',
	eft_bank: 'EFT Bank Transfer',
	pay_button: 'Pay immediately',
	complete_order: 'Complete Order',
	payment_failed: 'payment failed',
	payment_failed_trip: 'The current payment method is unavailable or failed, please select another payment method',
}
