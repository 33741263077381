import BasicLayout from "../../layout/basic"

const promotionRoutes = [
	//******************组合套餐模块（2）******************
	{
		path: "/combo/item/*",
		name: "combo",
		meta: {
			module: "combo"
		},
		component: () => import("@/views/promotion/combo/detail")
	},
	{
		path: "/combo/payment",
		name: "combo_payment",
		meta: {
			module: "combo",
		},
		component: () => import("@/views/promotion/combo/payment")
	},

	//******************秒杀模块（3）******************
	{
		path: "/seckill/item/*",
		name: "seckill_detail",
		meta: {
			module: "seckill",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/promotion/seckill/detail")
	},
	{
		path: "/seckill",
		name: "seckill",
		meta: {
			module: "seckill",
			mainCss: {
				width: "100%"
			}
		},
		component: () => import("@/views/promotion/seckill/list")
	},
	{
		path: "/seckill/payment",
		name: "seckill_payment",
		meta: {
			module: "seckill",
		},
		component: () => import("@/views/promotion/seckill/payment")
	},

	//******************团购模块（3）******************
	{
		path: "/groupbuy/item/*",
		name: "groupbuy_detail",
		meta: {
			module: "groupbuy",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/promotion/groupbuy/detail")
	},
	{
		path: "/groupbuy",
		name: "groupbuy",
		meta: {
			module: "groupbuy",
			mainCss: {
				width: "100%"
			}
		},
		component: () => import("@/views/promotion/groupbuy/list")
	},
	{
		path: "/groupbuy/payment",
		name: "groupbuy_payment",
		meta: {
			module: "groupbuy",
		},
		component: () => import("@/views/promotion/groupbuy/payment")
	},

	//******************专题活动模块（4）******************
	{
		path: "/topic/item/*",
		name: "topic_detail",
		meta: {
			module: "topic"
		},
		component: () => import("@/views/promotion/topic/detail")
	},
	{
		path: "/topic",
		name: "topic",
		meta: {
			module: "topic"
		},
		component: () => import("@/views/promotion/topic/list")
	},
	{
		path: "/topic/goods/*",
		name: "topic_goods_detail",
		meta: {
			module: "topic",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/promotion/topic/goods_detail")
	},
	{
		path: "/topic/payment",
		name: "topic_payment",
		meta: {
			module: "topic",
		},
		component: () => import("@/views/promotion/topic/payment")
	},

	//*************折扣活动模块（5）*******************
	{
		path: "/rebate.html",
		name: "rebate",
		meta: {
			module: "rebate"
		},
		component: () => import("@/views/promotion/rebate/list")
	},
	{
		path: "/new.html",
		name: "new",
		meta: {
			module: "new"
		},
		component: () => import("@/views/promotion/new/list")
	},
	{
		path: "/hot.html",
		name: "hot",
		meta: {
			module: "hot"
		},
		component: () => import("@/views/promotion/hot/list")
	},
]

const cmsRoutes = [{
		path: "/notice",
		name: "notice",
		meta: {
			module: "notice",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/cms/notice/list")
	},
	{
		path: "/notice/item/*",
		name: "notice_detail",
		meta: {
			module: "notice",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/cms/notice/detail")
	},
	{
		path: "/help",
		name: "help",
		meta: {
			module: "help",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/cms/help/list"),
		children: [{
			path: "/help/listother/*",
			name: "list_other",
			meta: {
				module: "help",
				backgroundColor: "#fff"
			},
			component: () => import("@/views/cms/help/listother")
		}]
	},
	{
		path: "/help/item/*",
		name: "help_detail",
		meta: {
			module: "help",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/cms/help/detail")
	}
]

const goodsRoutes = [
	//******************商品模块（6）******************
	{
		path: "/brand.html",
		name: "brand",
		meta: {
			module: "goods",
			mainCss: {
				width: "100%"
			}
		},
		component: () => import("@/views/goods/brand")
	},
	{
		path: "/cart",
		name: "cart",
		meta: {
			module: "goods"
		},
		component: () => import("@/views/goods/cart")
	},
	{
		path: "/category.html",
		name: "category",
		meta: {
			module: "goods"
		},
		component: () => import("@/views/goods/category")
	},
	{
		path: "/coupon",
		name: "coupon",
		meta: {
			module: "goods"
		},
		component: () => import("@/views/goods/coupon")
	},
	{
		path: "/product-*.html",
		name: "detail",
		meta: {
			module: "goods",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/goods/detail")
	},
	{
		path: "/list-*.html",
		name: "list",
		meta: {
			module: "goods",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/goods/list")
	},
	{
		path: "/list.html",
		name: "list",
		meta: {
			module: "goods",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/goods/list")
	}
]

// 其他模块
export default {
	path: "/",
	component: BasicLayout,
	redirect: "/index",
	name: "Index",
	children: [{
			path: "/index*",
			name: "index",
			meta: {
				mainCss: {
					width: "100%"
				}
			},
			component: () => import("@/views/index/index")
		},

		...goodsRoutes,
		...cmsRoutes,
		...promotionRoutes
	]
}
