export const lang = {
	//title为每个页面的标题
	title: '訂單清單',
	my_order: '我的訂單',
	all_orders: '全部訂單',
	pending_payment: '待付款',
	delivered: '待發貨',
	receipt: '待收貨',
	comment: '待評估',
	product_info: '商品資訊',
	unit_price: '單價',
	quantity: '數量',
	payment: '實付款',
	order_status: '訂單狀態',
	operation: '操作',
	order_number: '訂單號',
	order_details: '訂單詳情',
	comment: '評估',
	review: '追評',
	no_order: '暫無相關訂單',
	prevtext: '上一頁',
	nexttext: '下一頁', 
}
