export const lang = {
	//title为每个页面的标题
	title:'商品詳情',
	video:'視頻',
	picture:'圖片',
	discount:'限時折扣',
	day:'天',
	hour:'小時',
	minute:'分鐘',
	seconds:'秒',
	s_price:'銷售價',
	o_price:'原價',
	m_price:'會員價',
	mk_price:'市場價',
	evaluate:'累計評估',
	sales:'累計銷量',
	brand:'品牌',
	coupon:'優惠券',
	service:'服務',
	details:'商品詳情',
	attribute:'商品内容',
	reviews:'商品評估',
	buynow:'立即購買',
	joincart:'加入購物車',
	out_stock:'庫存不足',
	quantity:'數量',
	focus_goods:'關注商品',
	bundling:'組合套餐價',
	no_reviews:'該商品暫無評估哦',
	all_review:'全部評估',
	select_attributes:'請選擇要購買的產品内容',
}
