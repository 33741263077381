<template>
	<div class="header">
		<div class="header-top">
            <div class="top-content">
                <div class="top-right">
                    <router-link v-if="!logined" to="/login">{{$lang("common.login")}}</router-link>
                    <div v-if="logined" class="member-info">
                        <router-link to="/member/index">{{ member.nickname || member.username }}</router-link>
                        <span @click="logout">{{$lang("common.logout")}}</span>
                    </div>
                    <router-link v-if="!logined" to="/register" :title="$lang('common.register')">{{$lang("common.register")}}</router-link>
                    <router-link to="/member/order_list" :title="$lang('common.myOrder')">{{$lang("common.myOrder")}}</router-link>
					<router-link to="/member/footprint" :title="$lang('common.myHistory')">{{$lang("common.myHistory")}}</router-link>
					<router-link to="/member/collection" :title="$lang('common.myAttention')">{{$lang("common.myAttention")}}</router-link>
					
                    <router-link to="/help" :title="$lang('common.help')">{{$lang("common.help")}}</router-link>
					<router-link to="/notice" :title="$lang('common.notice')">{{$lang("common.notice")}}</router-link>
                    <el-dropdown v-if="qrcode">
                        <span class="el-dropdown-link">
                            {{$lang("common.mobile_1")}}
                            <i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>
                                <div class="mobile-qrcode">
									<a href="https://m.huarenshop.com" :title="$lang('common.store')">
										<img :src="$imgnew('upload/qrcode/mobile_shop.jpg')" />
									</a>
								</div>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
					<!-- 语言切换 -->
					<el-dropdown class="el-dropdown-language">
					    <div class="language-selector">
							<template v-if="current_lang==='zh-cn'">
								&nbsp;简体中文
							</template>
							<template v-if="current_lang==='zh-hk'">
								&nbsp;繁体中文
							</template>
							<template v-if="current_lang==='en-us'">
								&nbsp;English
							</template>
					        <i class="el-icon-arrow-down el-icon--right"></i>
					    </div>
					    <el-dropdown-menu slot="dropdown" class="user-language">
							<el-dropdown-item >
								<div @click="handleCommand('zh-cn')">
									<span>&nbsp;简体中文</span>
								</div>
							</el-dropdown-item>
							<el-dropdown-item >
								<div @click="handleCommand('zh-hk')">
									<span>&nbsp;繁体中文</span>
								</div>
							</el-dropdown-item>
							<el-dropdown-item >
								<div @click="handleCommand('en-us')">
									<span>&nbsp;English</span>
								</div>
							</el-dropdown-item>
					    </el-dropdown-menu>
					</el-dropdown>
                </div>
            </div>
        </div>

		<ns-header-mid />

		<div class="nav_block" @mouseleave="navLeave">
			<div class="nav">
				<div class="shop-sort" @mouseover="shopHover" @mouseleave="shopLeave">
					<i class="iconfont icon-custom-menu"></i>
					<router-link to="/category.html">{{$lang("common.allCategory")}}</router-link>
				</div>

				<div class="shop-list-content" :class="forceExpand || isShopHover ? 'shop-list-active' : 'shadow'" :style="isIndex?indexStyle:''">
					<!-- 分类类型1 -->
					<div class="shop-list" v-if="categoryConfig.category ==1" @mouseover="shopHover" @mouseleave="shopLeave">
						<div class="list-item"
						v-for="(item, index) in goodsCategoryTree" :key="index" @mouseover="selectedCategory = item.category_id" @mouseleave="selectedCategory = -1">
							<router-link :to="{ path: '/list-' + item.category_id + '.html' }" target="_blank" :title="item.category_name">
							<div>
								<img class="category-img" v-if="categoryConfig.img == 1 && item.image" :src="$util.img(item.image)" :alt="item.category_name" />
								<p class="category-name">{{item.category_name }}</p>
							</div>
							<i class="el-icon-arrow-right" aria-hidden="true"></i>
							</router-link>
						</div>

					</div>
					<!-- 分类类型2 -->
					<div class="shop-list" :class="forceExpand || isShopHover || isIndex ? 'shop-list-active' : 'shadow'" v-else-if="categoryConfig.category ==2" @mouseover="shopHover" @mouseleave="shopLeave">
						<div class="list-item" v-for="(item, index) in goodsCategoryTree" :key="index" @mouseover="selectedCategory = item.category_id" @mouseleave="selectedCategory = -1">
							<router-link :to="{ path: '/list-' + item.category_id + '.html' }" target="_blank" :title="item.category_name">
							<div>
								<img class="category-img" v-if="categoryConfig.img == 1 && item.image" :src="$util.img(item.image)" />
								<p class="category-name">{{ item.category_name }}</p>
									
							</div>
							<i class="el-icon-arrow-right" aria-hidden="true"></i>
							</router-link>
							<div class="cate-part" v-if="item.child_list" :class="{ show: selectedCategory == item.category_id }">
								<div class="cate-part-col1">
									<div class="cate-detail">
										<div class="cate-detail-item2" >
											<div class="cate-detail-con2">
												<router-link
													v-for="(second_item, second_index) in item.child_list" :key="second_index"
													target="_blank"
													:to="{ path: '/list-' + second_item.category_id + '.html' }"
													:title="second_item.category_name"
												>
													<img class="cate-detail-img"
														v-if="categoryConfig.img == 1"
														:src="$util.img(second_item.image)" />
													<p class="category-name">{{second_item.category_name }}</p>
												</router-link>
											</div>
											
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					
					<!-- 分类类型3 -->
					<div class="shop-list" :class="forceExpand || isShopHover || isIndex ? 'shop-list-active' : 'shadow'" v-else-if="categoryConfig.category ==3" @mouseover="shopHover" @mouseleave="shopLeave">
						<div class="list-item" v-for="(item, index) in goodsCategoryTree" :key="index" @mouseover="selectedCategory = item.category_id" @mouseleave="selectedCategory = -1">
							<router-link :to="{ path: '/list-' + item.category_id + '.html' }" target="_blank" :title="item.category_name">
								<div class="list-item-left">
									<img class="category-img"
										v-if="categoryConfig.img == 1 && item.image"
										:src="$util.img(item.image)" />
									<p class="category-name">{{item.category_name }}</p>
								</div>
							</router-link>
							<div class="cate-part" v-if="item.child_list" :class="{ show: selectedCategory == item.category_id }">
								<div class="cate-part-col1">
									<div class="cate-detail">
										<dl class="cate-detail-item" v-for="(second_item, second_index) in item.child_list" :key="second_index">
											<dt class="cate-detail-tit">
												<router-link target="_blank" :to="{ path: '/list-' + second_item.category_id + '.html' }" :title="second_item.category_name">
													{{ second_item.category_name }}
												</router-link>
											</dt>
											<dd class="cate-detail-con" v-if="second_item.child_list">
												<router-link 
													v-for="(third_item, third_index) in second_item.child_list"
													:key="third_index"
													target="_blank"
													:to="{ path: '/list-' + third_item.category_id + '.html' }" 
													:title="third_item.category_name"
												>
												<img class="cate-detail-img"
													v-if="categoryConfig.img == 1"
													:src="$util.img(third_item.image)" />
													<p class="category-name">{{third_item.category_name }}</p>
												</router-link>
											</dd>
										</dl>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<nav>
					<ul>
						<li v-for="(nav_item, nav_index) in navList" :key="nav_index" :class="nav_item.url == navSelect ? 'nav-active' : ''" @mouseenter="navHover(nav_index)">
							<span>
								<router-link :to="nav_item.url" :title="nav_item.nav_title">{{ nav_item.nav_title }}</router-link>
								<sup v-if="nav_item.nav_title ==  '新品上市' || nav_item.nav_title == 'New arrivals'" class="nav-badge ">NEW</sup>
								<sup v-if="nav_item.nav_title ==  '爆款热销' || nav_item.nav_title == 'Hot Sales'" class="nav-badge hot_bc">HOT</sup>
							</span>
						</li>
					</ul>
				</nav>
			</div>

			<!-- 鼠标经过弹出框 -->
			<div class="mouse-content-show" v-if="isNavHover && navList[currentNavIndex].child" @mouseenter="isNavHover=true" @mouseleave="isNavHover=false">
				<!-- 商品列表 -->
				<ul class="content-item" v-if="isBrand==false">
					<li v-for="(item,index) in navList[currentNavIndex].child" :key="index">
						<div @click="goto(navList[currentNavIndex].nav_type,index)">
							<img :src="item.image_url"/>
							<span>{{item.name}}</span>
						</div>
					</li>
				</ul>
				
				<!-- 品牌列表 -->
				<ul class="content-item-brand" v-else>
					<li v-for="(item,index) in navList[currentNavIndex].child" :key="index">
						<div>
							<router-link :to="'/list.html?brand_id='+item.brand_id" :title="item.name" target="_blank">
								<img :src="item.image_url"/>
								<span>{{ item.name }}</span>
							</router-link>
						</div>
					</li>
				</ul>
			</div>
		</div>
		
		<!-- 悬浮搜索 -->
		<div class="fixed-box" :style="{ display: isShow ? 'block' : 'none' }">
			<div class="header-top">
				<div class="top-content">
					<div class="top-right">
						<router-link v-if="!logined" to="/login">{{$lang("common.login")}}</router-link>
						<div v-if="logined" class="member-info">
							<router-link to="/member/index">{{ member.nickname || member.username }}</router-link>
							<span @click="logout">{{$lang("common.logout")}}</span>
						</div>
						<router-link v-if="!logined" to="/register">{{$lang("common.register")}}</router-link>
						<router-link to="/member/order_list" :title="$lang('common.myOrder')">{{$lang("common.myOrder")}}</router-link>
						<router-link to="/member/footprint" :title="$lang('common.myHistory')">{{$lang("common.myHistory")}}</router-link>
						<router-link to="/member/collection" :title="$lang('common.myAttention')">{{$lang("common.myAttention")}}</router-link>
						
						<router-link to="/help" :title="$lang('common.help')">{{$lang("common.help")}}</router-link>
						<router-link to="/notice" :title="$lang('common.notice')">{{$lang("common.notice")}}</router-link>
						<el-dropdown v-if="qrcode">
							<span class="el-dropdown-link">
								{{$lang("common.mobile_1")}}
								<i class="el-icon-arrow-down el-icon-right"></i>
							</span>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item>
									<div class="mobile-qrcode">
										<a href="https://m.huarenshop.com" :title="$lang('common.store')">
											<img :src="$imgnew('upload/qrcode/mobile_shop.jpg')" />
										</a>
									</div>
								</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
						<!-- 语言切换 -->
						<el-dropdown class="el-dropdown-language">
							<div class="language-selector">
								<template v-if="current_lang==='zh-cn'">
									&nbsp;简体中文
								</template>
								<template v-if="current_lang==='zh-hk'">
									&nbsp;繁体中文
								</template>
								<template v-if="current_lang==='en-us'">
									&nbsp;English
								</template>
								<i class="el-icon-arrow-down el-icon--right"></i>
							</div>
							<el-dropdown-menu slot="dropdown" class="user-language">
								<el-dropdown-item >
									<div @click="handleCommand('zh-cn')">
										<span>&nbsp;简体中文</span>
									</div>
								</el-dropdown-item>
								<el-dropdown-item >
									<div @click="handleCommand('zh-hk')">
										<span>&nbsp;繁体中文</span>
									</div>
								</el-dropdown-item>
								<el-dropdown-item >
									<div @click="handleCommand('en-us')">
										<span>&nbsp;English</span>
									</div>
								</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
				</div>
			</div>
			<div class="header-search">
				<el-row>
					<el-col :span="6">
						<router-link to="/" class="logo-wrap"><img :src="$img(siteInfo.logo)" /></router-link>
					</el-col>
					<el-col :span="13">
						<div class="in-sousuo">
							<div class="sousuo-box">
								<el-dropdown @command="handleCommand" trigger="click">
									<span class="el-dropdown-link">
										{{ searchTypeText }}
										<i class="el-icon-arrow-down"></i>
									</span>
									<el-dropdown-menu slot="dropdown">
										<el-dropdown-item command="goods">{{$lang("common.goods")}}</el-dropdown-item>
									</el-dropdown-menu>
								</el-dropdown>
								<input type="text" :placeholder="defaultSearchWords" v-model="keyword" @keyup.enter="search" maxlength="50" />
								<el-button type="primary" size="small" @click="search">{{$lang("common.search")}}</el-button>
							</div>
						</div>
					</el-col>
					<el-col :span="5">
						<div class="cart-wrap">
							<router-link class="cart" to="/cart">
								<span>{{$lang("common.myCart")}}</span>
								<el-badge v-if="cartCount" :value="cartCount" type="primary"><i class="iconfont icongouwuche"></i></el-badge>
								<i v-else class="iconfont icon-gouwuche"></i>
							</router-link>
						</div>
					</el-col>
				</el-row>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex"
import { getToken } from "@/utils/auth"
import Lang from "@/utils/lang.js"
import NsHeaderMid from './NsHeaderMid';
import { tree , categoryConfig} from '@/api/goods/goodscategory';
import { navList } from '@/api/website';
import { apiDefaultSearchWords } from "@/api/pc"
export default {
	props: {
		forceExpand: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		...mapGetters(['is_show',"wapQrcode", "member", "addonIsExit", "lang", "siteInfo", "cartCount"]),
		qrcode: function() {
			return this.wapQrcode === "" ? "" : this.wapQrcode.path.h5.img
		},
		logined: function() {
			return this.member !== undefined && this.member !== "" && this.member !== {}
		}
	},
	data() {
		return {
			languages:[
				{
					name:'中文',
					icon:'../../assets/images/china.png'
				},{
					name:'English',
					icon:'../../assets/images/english.png'
				}
			],
			top_notice: '',
			isShopHover: false,
			isNavHover: false,
			isIndex: false,
			thisRoute: '',
			goodsCategoryTree: [],
			categoryConfig:{},
			selectedCategory: -1,
			navList: [],
			navSelect: '',
			isHide:false,
			indexStyle:{
				border : 'inherit',
				backgroundColor: 'rgba(255,255,255,0.8)'
			},
			currentNavIndex: -1,//当前指向的nav下标
			isBrand: false, // 是否显示的是品牌列表
			// 悬浮搜索
			searchType: "goods",
			searchTypeText: "商品",
			keyword: "",
			defaultSearchWords: "",
			isShow: false,
			current_lang: 'zh-cn',
		};
	},
	components: {
		NsHeaderMid,
		mapGetters
	},
	beforeCreate() {},
	created() {
		this.$store.dispatch("site/qrCodes")
		this.$store.dispatch("member/member_detail")
		this.$store.dispatch("site/defaultFiles")
		this.$store.dispatch("site/addons")
		this.$store.dispatch('cart/cart_count');
		this.$store.dispatch("site/siteInfo") // 站点信息
		this.getCategoryConfig();
		this.getDefaultSearchWords(); // 默认搜索类型
		this.nav();
		if (this.$route.path == '/' || this.$route.path == '/index') this.isIndex = true;
		if (localStorage.getItem('lang')) {
			this.current_lang = localStorage.getItem('lang');
		} else {
			this.current_lang = this.lang;
		}
	},
	mounted() {
		window.addEventListener("scroll", this.handleScroll)
	},
	watch: {
		$route: function(curr) {
			this.initNav(curr.path);
			let judgeLength = localStorage.getItem('isAdList')
			if (this.$route.path == '/' || this.$route.path == '/index') this.isIndex = true;
			else this.isIndex = false;

			if (curr.path == '/list') {
				this.navSelect = '';
			}
		}
	},
	methods: {
		logout() {
			this.$store.dispatch("member/logout")
		},
		handleCommand(val){
			Lang.change(val)
		},
		// 获取配置
		getCategoryConfig(){
			categoryConfig({
				
			})
			.then(res => {
				if (res.code == 0 && res.data) {
					this.categoryConfig = res.data;
					this.getTree(res.data);
				}
			})
			.catch(err => {
				this.$message.error(err.message);
			});
		},
		getTree(categoryConfig) {
			tree({
				level: 3,
				template: 2
			})
				.then(res => {
					if (res.code == 0 && res.data) {
						this.goodsCategoryTree = res.data || [];
						for(let i =0;i<this.goodsCategoryTree.length;i++) {
							if(this.goodsCategoryTree[i].child_list > 3) {
								this.isHide = true
							}
						}
					}
				})
				.catch(err => {
					this.$message.error(err.message);
				});
		},
		nav() {
			navList({})
				.then(res => {
					if (res.code == 0 && res.data) {
						this.navList = res.data;
						for (let i in this.navList) {
							this.navList[i]['url'] = JSON.parse(this.navList[i]['nav_url']).url;
						}
						this.initNav(this.$route.path);
					}
				})
				.catch(err => {
					this.$message.error(err.message);
				});
		},
		navUrl(url, target) {
			if (!url) return;
			if (url.indexOf('http') == -1) {
				if (target) this.$router.pushToTab({ path: url });
				else this.$router.push({ path: url });
			} else {
				if (target) window.open(url);
				else window.location.href = url;
			}
		},
		initNav(path) {
			for (let i in this.navList) {
				if (this.navList[i]['url'] == path) {
					this.navSelect = path;
					continue;
				}
			}
		},
		
		//鼠标移入显示商品分类
		shopHover() {
			this.isShopHover = true;
			this.isNavHover = false;
		},
		//鼠标移出商品分类隐藏
		shopLeave() {
			this.isShopHover = false;
		},
		navHover(index){
			if(index || index == 0){
				this.currentNavIndex = index;
				if(index!=0) this.isNavHover = true;
				if(this.navList[index].nav_type == 'brand'){
					this.isBrand = true;
				}else{
					this.isBrand = false;
				}
			}
		},
		navLeave(){
			this.isNavHover = false;
		},
		goto(type,index){
			let id = 0;
			switch(type){
				case 'goods':
					id = this.navList[this.currentNavIndex].child[index].goods_id;
					this.$router.pushToTab('/product-' + id + '.html');
					break;
				case 'brand':
					id = this.navList[this.currentNavIndex].child[index].brand_id;
					this.$router.pushToTab('/list.html?brand_id=' + id);
					break;
				case 'category':
					id = this.navList[this.currentNavIndex].child[index].category_id;
					this.$router.pushToTab('/list.html?category_id=' + id);
					break;
				case 'groupbuy':
					id = this.navList[this.currentNavIndex].child[index].groupbuy_id;
					this.$router.pushToTab('/groupbuy/item/' + id);
					break;
				case 'seckill':
					id = this.navList[this.currentNavIndex].child[index].seckill_id;
					this.$router.pushToTab('/seckill/item/' + id);
					break;
				default:
					break;
			}
		},
		goSku(skuId) {
			this.$router.pushToTab('/product-' + skuId + '.html');
		},
		// 监听滚动条
		handleScroll() {
			var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop

			if (scrollTop >= 380) {
				this.isShow = true
			} else {
				this.isShow = false
			}
		},
		destroyed() {
			// 离开该页面需要移除这个监听的事件，不然会报错
			window.removeEventListener("scroll", this.handleScroll)
		},
		/**
		 * 默认搜索类型（goods/shop）
		 */
		getDefaultSearchWords() {
			apiDefaultSearchWords({}).then(res => {
				if (res.code == 0 && res.data.words) {
					this.defaultSearchWords = res.data.words
				}
			})
		},
		search() {
			if (this.searchType == "goods") this.$router.push({
				path: "/list.html",
				query: {
					keyword: this.keyword
				}
			})
			else this.$router.push({
				path: "/street",
				query: {
					keyword: this.keyword
				}
			})
		}
	}
};
</script>

<style scoped lang="scss">
.header {
	width: 100%;
	height: 180px;
	background-color: #ffffff;

	.shadow {
		box-shadow: -1px 3px 12px -1px rgba(0, 0, 0, 0.3);
	}
	.border {
		border: 1px solid #f5f5f5;
	}
	
	.nav_block {
		width: 100%;
		height: 41px;
		background-color: #fff;
	}

	.nav > .shop-list-active{
		border: 1px solid $base-color;
	}

	.nav {
		width: 1210px;
		height: 41px;
		margin: 0 auto;
		position: relative;
		box-sizing: border-box;
		.shop-sort {
			width: 210px;
			height: 41px;
			// color: #fff;
			background-color: #FFF;
			float: left;
			padding: 10px 10px 11px 12px;
			box-sizing: border-box;
			&:hover{
				// border: 1px solid $base-color;
				// box-shadow: 0 0 0 1px $base-color;
				// outline: 1px solid $base-color;
				// border-top: 1px solid $base-color;
				// margin-bottom: 5px;
			}
			a {
				font-size: 15px;
				line-height: 20px;
				// float: left;
				color: $ns-text-color-black;
				&:hover{
					color: $base-color;
				}
			}
			i {
				font-size: 14px;
				padding-right: 10px;
				// float: right;
			}
		}
		
		.shop-list-content{
			width: 210px;
			height: 500px;
			position: absolute;
			left: 0;
			top: 41px;
			background-color: #FFF;
			display: none;
			padding: 0;
			box-sizing: border-box;
			font-size: $ns-font-size-base;
			z-index: 10;
			color:  #FFFFFF;
			&::-webkit-scrollbar{
				display: none;
			}
			// overflow: hidden;
			.shop-list {
				width: 210px;
				height: 500px;
				overflow-y: auto;
				overflow-x: hidden;
				// box-shadow: -1px 3px 12px -1px rgba(0, 0, 0, 0.3);
				&::-webkit-scrollbar{
					display: none;
				}
				a:hover {
					color: $base-color;
				}
				.list-item {
					// padding-left: 12px;
					box-sizing: border-box;
					// min-height:50px;
					// height: 45px;
					// line-height: 50px;
					// padding:13px;
					a {
						display: flex;
						justify-content: space-between;
						align-items: center;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						padding:6px 30px;
						// width: 95%;
						// display: block;
						color:  $ns-text-color-black;
						>div{
							display: flex;
							align-items: center;
						}
					}
					&:hover {
						// background-color: #000;
						background-color: #EE5523;
						a{ color: #fff; }
						-webkit-transition: 0.2s ease-in-out;
						-moz-transition: -webkit-transform 0.2s ease-in-out;
						-moz-transition: 0.2s ease-in-out;
						transition: 0.2s ease-in-out;
						a:hover {
							height: 100%;
							color: #FFF;
						}
					}
					span:hover {
						color: #FFF;
					}
					.category-img{
						width:17px;
						height: 17px;
						margin-right: 10px;
					}
					.category-name{
						overflow:hidden;
						text-overflow:ellipsis;
						white-space:nowrap;
					}
					.item-itm {
						font-size: 14px;
						line-height: 15px; 
						height: 28px;
						overflow: hidden;
						
						a{
							margin-top: 5px;
							margin-right: 10px;
							color:#BFBFBF;
							&:hover{
								color: #FD274A !important;
							}
						}
						
						&.item-itm-img{
							margin-left: 27px;
						}
					}
					.cate-part {
						display: none;
						position: absolute;
						left: 210px;
						top: 0;
						z-index: auto;
						// width: 998px;
						width: 760px;
						height: 498px;
						overflow-y: auto;
						border: 1px solid #f7f7f7;
						background-color: #fff;
						-webkit-box-shadow: 2px 0 5px rgba(0, 0, 0, 0.05);
						-moz-box-shadow: 2px 0 5px rgba(0, 0, 0, 0.05);
						box-shadow: 2px 0 5px rgba(0, 0, 0, 0.05);
						-webkit-transition: top 0.25s ease;
						-o-transition: top 0.25s ease;
						-moz-transition: top 0.25s ease;
						transition: top 0.25s ease;
						&.show {
							display: block;
						}
						&::-webkit-scrollbar{
							display: none;
						}
					}
					.cate-part-col1 {
						float: left;
						width: 100%;
						.cate-detail-item2 {
							position: relative;
							min-height: 36px;
							padding-left: 20px;
							&:last-child{
								margin-top: 20px;
								margin-bottom: 30px;
							}
							.cate-detail-con2 {
								overflow: hidden;
								//padding: 6px 0 6px 17px;
								display: flex;
								flex-wrap: wrap;
								border-top: 1px dashed #eee;
								a {
									justify-content: start;
									font-size: 14px;
									height:45px;
								    /* float: left; */
								    /*margin: 4px 40px 4px 0;*/
								    padding: 10px 25px;
								    white-space: nowrap;
								    line-height: 45px;
								    color: #666;
									/*width: calc((100% - 120px) / 4);*/
									display: flex;
									box-sizing: border-box;
									/*margin-top: 20px;*/
									.cate-detail-img{
										width:30px;
										height: 30px;
										margin-right: 10px;
									}
									&:nth-child(4n+4){
										margin-right: 0;
									}
								}
								a:hover{
									color: #FD274A;
								}
							}
							&:first-child .cate-detail-con {
								border-top: none;
							}
						}
						.cate-detail-item {
							position: relative;
							min-height: 36px;
							padding-left: 20px;
							&:last-child{
								margin-bottom: 30px;
							}
							.cate-detail-tit {
								margin-top:8px;
								margin-right: 20px;
								white-space: nowrap;
								text-overflow: ellipsis;
								font-weight: 700;
								a {
									display: block;
									color: #333333;
									padding: 5px 8px;
								}
								a:hover{
									color: #FD274A;
								}
								
							}
							.cate-detail-con {
								overflow: hidden;
								//padding: 6px 0 6px 17px;
								display: flex;
								flex-wrap: wrap;
								// border-top: 1px dashed #eee;
								a {
									justify-content: start;
									font-size: 14px;
									height:30px;
								    /* float: left; */
								    /*margin: 4px 40px 4px 0;*/
								    padding: 0 10px;
								    white-space: nowrap;
								    line-height: 30px;
								    color: #666;
									/*width: calc((100% - 120px) / 4);*/
									display: flex;
									box-sizing: border-box;
									/*margin-top: 20px;*/
									.cate-detail-img{
										width:30px;
										height: 30px;
										margin-right: 10px;
									}
									&:nth-child(4n+4){
										margin-right: 0;
									}
								}
								a:hover{
									color: #FD274A;
								}
							}
							&:first-child .cate-detail-con {
								border-top: none;
							}
						}
					}
				}
			}
		}
		
		.shop-list-active {
			display: block;
		}
		nav {
			width: 990px;
			height: 36px;
			float: left;
			font-size: 14px;
			overflow: hidden;
			ul {
				margin: 0;
				padding: 0;
				width: 100%;
				height: 100%;
				li {
					cursor: pointer;
					list-style: none;
					float: left;
					padding: 8px 24px;
					span {
						position: relative;
						.nav-badge {
							position: absolute;
							top: 0px;
							right: 0px;
							transform: translateY(-50%) translateX(100%);
							background-color: #ff9900;
							border-radius: 10px;
							color: #fff;
							display: inline-block;
							font-size: 10px;
							height: 18px;
							line-height: 18px;
							padding: 0 6px;
							text-align: center;
							white-space: nowrap;
							border: 1px solid #fff;
						}
						.hot_bc {
							background-color: #EA4D19;
						}
					}
					a {
						&:hover {
							color: $base-color;
						}
					}
				}
				li:hover {
					color: $base-color;
					font-weight: bold;
				}
				.router-link-active {
					color: $base-color;
					font-weight: bold;
					/**border-bottom: 2px solid  #FD274A;*/
					
					&:hover{
						color: $base-color;
					}
					a {
						color: $base-color;
					}
				}
			}
		}
	}

	.header-top {
        width: 100%;
        height: 31px;
        font-size: 12px;
        background-color: #f2f2f2;
        color: #646464;

        .el-dropdown {
            font-size: $ns-font-size-sm;
        }
        .top-content {
            width: $width;
            height: 100%;
            margin: 0 auto;
            .top-left {
                height: 100%;
                float: left;
                height: 100%;
                line-height: 31px;
                .change-city {
                    cursor: pointer;
                    margin-left: 5px;
                    &:hover {
                        color: $base-color;
                        border-color: $base-color;
                    }
                }
            }
            .top-right {
                height: 100%;
                float: right;
                font-size: $ns-font-size-sm;
                //color: $ns-text-color-black;
                a {
                    float: left;
                    line-height: 31px;
                    padding: 0 10px;
                    color: #646464;
                    &:hover {
                        color: $base-color;
                    }
                }
                div {
                    float: left;
                    height: 100%;
                    margin-left: 10px;
                    cursor: pointer;
                    line-height: 31px;
                    padding: 0 5px;
                    color: $base-color;

                    &.member-info {
                        margin-left: 0;
                        span:first-child {
                            margin-right: 10px;
                        }
                    }
                    span:hover {
                        color: $base-color;
                    }
                    &.el-dropdown:hover {
                        color:#ff9900;
                    }
                }
            }
        }
    }

	// 悬浮搜索
	.fixed-box {
		width: 100%;
		background-color: #ffffff;
		position: fixed;
		top: 0;
		z-index: 999;
		border-bottom: 2px solid #e7e7e8;
		.header-search {
			width: $width;
			height: 68px;
			padding: 10px 0px;
			margin: 0 auto;
			.logo-wrap {
				width: 240px;
				height: 68px;
				line-height: 68px;
				display: block;
				img {
					max-width: 100%;
					max-height: 100%;
				}
			}
			.in-sousuo {
				width: 550px;
				padding: 16px 0;
				.sousuo-box {
					width: 100%;
					height: 36px;
					background-color: #fff;
					border: 2px solid $base-color;
					box-sizing: border-box;
					border-radius: 10px;
					overflow: hidden;
					.el-dropdown {
						padding: 0 10px;
						cursor: pointer;
						&::after {
							content: '';
							border-left: 1px solid #cfcfcf;
							margin-left: 5px;
						}
					}
					input {
						width: 380px;
						height: 22px;
						background: none;
						outline: none;
						border: none;
						margin: 4px;
					}
					button {
						border-radius: 0;
						float: right;
						padding: 10px 15px;
					}
				}
			}
			.cart-wrap {
				position: relative;
				float: right;

				.cart {
					margin-top: 15px;
					width: 95px;
					height: 36px;
					padding: 0 28px 0 19px;
					background-color: #fff;
					border: 1px solid #dfdfdf;
					color: $base-color;
					font-size: $ns-font-size-sm;
					display: block;
					position: absolute;
					right: 0;
					z-index: 101;
					span {
						cursor: pointer;
						line-height: 38px;
						margin-right: 10px;
					}
				}
				&:hover {
					.cart {
						border-bottom: 1px solid #ffffff;
					}
				}
			}
		}
	}
}

.mouse-content-show{
	position: relative;
	top: -1px;
	width: 100%;
	// height: 245px;
	background-color: #FFF;
	z-index: 999;
	display: flex;
	justify-content: center;
	
	.content-item{
		width: 75%;
		margin: 25px 0;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		
		li{
			width: 20%;
			box-sizing: border-box;
			height: 200px;
			display: flex;
			justify-content: center;
			
			div{
				padding: 0 15px;
				display: flex;
				flex-direction: column;
				align-items: center;
				cursor:pointer;
				// background-color: pink;
				
				// &:hover {
				// 	border: 1px solid pink;
				// }
				
				span{
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					overflow: hidden;
				}
			}
			img{
				// display: block;
				// min-width: 150px;
				// min-height: 150px;
				max-width: 150px;
				max-height: 150px;
			}
		}
	}
	
	.content-item-brand{
		width: 75%;
		margin: 25px 0;
		// background-color: #03A9F4;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		
		li{
			width: 20%;
			box-sizing: border-box;
			height: 60px;
			display: flex;
			justify-content: center;
			
			div{
				padding: 0 15px;
				display: flex;
				flex-direction: column;
				align-items: center;
				cursor:pointer;
				
				span{
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					overflow: hidden;
				}
			}
			img{
				transform:scale(0.8);
				// display: block;
				// max-width: 80px;
				max-height: 60px;
			}
		}
	}
}
</style>
