export const lang = {
	//title为每个页面的标题
	title:'編輯收貨地址',
	first_name:'名（First name）',
	last_name:'姓（Last name）',
	first_placeholder:'拼音或英文名',
	last_placeholder:'拼音或英文姓',
	address:'詳細地址（Address）',
	address_placeholder:'請精確到樓棟門牌號',
	city:'區（Suburb）',
	country:'國家（Country）',
	country_placeholder:'請選擇國家（Country）',
	province_placeholder:'請選擇州',
	post_code:'郵遞區號（Post）',
	postcode_placeholder:'請輸入正確的郵編',
	mobile:'電話（Phone）',
	mobile_placeholder:'請輸入收貨人手機號（Phone）,例如：0* **** ****',
	is_default:'是否默認',
	save:'保存',
	yes:'是',
	no:'否',
	mobile_tips:'手機號不能為空',
	region_tips:'請選擇地區',
	country_tips:'請選擇國家',
	first_name_tips:'請輸入收貨人的名（First name）',
	last_name_tips:'請輸入收貨人的姓（Last name）',
	post_code_tips:'請輸入郵編',
	address_tips:'請輸入詳細地址',
	phone_tips:'請輸入電話號碼（Phone）,例如：0* **** ****', 
}
