<template>
    <div>
        <div class="header-top">
            <div class="top-content">
                <div class="top-right">
                    <router-link v-if="!logined" to="/login">{{$lang("common.login")}}</router-link>
                    <div v-if="logined" class="member-info">
                        <router-link to="/member/index">{{ member.nickname || member.username }}</router-link>
                        <span @click="logout">{{$lang("common.logout")}}</span>
                    </div>
                    <router-link v-if="!logined" to="/register" :title="$lang('common.register')">{{$lang("common.register")}}</router-link>
                    <router-link to="/member/order_list" :title="$lang('common.myOrder')">{{$lang("common.myOrder")}}</router-link>
					<router-link to="/member/footprint" :title="$lang('common.myHistory')">{{$lang("common.myHistory")}}</router-link>
					<router-link to="/member/collection" :title="$lang('common.myAttention')">{{$lang("common.myAttention")}}</router-link>
					
                    <router-link to="/help" :title="$lang('common.help')">{{$lang("common.help")}}</router-link>
					<router-link to="/notice" :title="$lang('common.notice')">{{$lang("common.notice")}}</router-link>
                    <el-dropdown v-if="qrcode">
                        <span class="el-dropdown-link">
                            {{$lang("common.mobile_1")}}
                            <i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>
                                <div class="mobile-qrcode">
									<a href="https://m.huarenshop.com" :title="$lang('common.store')">
										<img :src="$imgnew('upload/qrcode/mobile_shop.jpg')" />
									</a>
								</div>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                   
					<!-- 语言切换 -->
                    <el-dropdown class="el-dropdown-language">
					    <div class="language-selector">
							<template v-if="current_lang==='zh-cn'">
								&nbsp;简体中文
							</template>
							<template v-if="current_lang==='zh-hk'">
								&nbsp;繁体中文
							</template>
							<template v-if="current_lang==='en-us'">
								&nbsp;English
							</template>
					        <i class="el-icon-arrow-down el-icon--right"></i>
					    </div>
					    <el-dropdown-menu slot="dropdown" class="user-language">
							<el-dropdown-item >
								<div @click="handleCommand('zh-cn')">
									<span>&nbsp;简体中文</span>
								</div>
							</el-dropdown-item>
							<el-dropdown-item >
								<div @click="handleCommand('zh-hk')">
									<span>&nbsp;繁体中文</span>
								</div>
							</el-dropdown-item>
							<el-dropdown-item >
								<div @click="handleCommand('en-us')">
									<span>&nbsp;English</span>
								</div>
							</el-dropdown-item>
					    </el-dropdown-menu>
					</el-dropdown>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex"
    import { getToken } from "@/utils/auth"
	import Lang from "@/utils/lang.js"

    export default {
        props: {},
        data() {
            return {
				languages:[
					{
						name:'中文',
						icon:'../../assets/images/china.png'
					},{
						name:'English',
						icon:'../../assets/images/english.png'
					}
				],
                current_lang: 'zh-cn',
			}
        },
        created() {
            this.$store.dispatch("site/qrCodes")
            this.$store.dispatch("member/member_detail")
            this.$store.dispatch("site/defaultFiles")
            this.$store.dispatch("site/addons")
            if (localStorage.getItem('lang')) {
			    this.current_lang = localStorage.getItem('lang');
            } else {
                this.current_lang = this.lang;
            }
        },
        mounted() {},
        watch: {},
        methods: {
            logout() {
                this.$store.dispatch("member/logout")
            },
			handleCommand(val){
				Lang.change(val)
			}
        },
        components: {},
        computed: {
            ...mapGetters(["wapQrcode", "member", "addonIsExit", "lang"]),
            qrcode: function() {
                return this.wapQrcode === "" ? "" : this.wapQrcode.path.h5.img
            },
            logined: function() {
                return this.member !== undefined && this.member !== "" && this.member !== {}
            }
        }
    }
</script>

<style scoped lang="scss">
    .header-top {
        width: 100%;
        height: 31px;
        font-size: 12px;
        background-color: #f2f2f2;
        color: #646464;

        .el-dropdown {
            font-size: $ns-font-size-sm;
        }
        .top-content {
            width: $width;
            height: 100%;
            margin: 0 auto;
            .top-left {
                height: 100%;
                float: left;
                height: 100%;
                line-height: 31px;
                .change-city {
                    cursor: pointer;
                    margin-left: 5px;
                    &:hover {
                        color: $base-color;
                        border-color: $base-color;
                    }
                }
            }
            .top-right {
                height: 100%;
                float: right;
                font-size: $ns-font-size-sm;
                //color: $ns-text-color-black;
                a {
                    float: left;
                    line-height: 31px;
                    padding: 0 10px;
                    color: #646464;
                    &:hover {
                        color: $base-color;
                    }
                }
                div {
                    float: left;
                    height: 100%;
                    margin-left: 10px;
                    cursor: pointer;
                    line-height: 31px;
                    padding: 0 5px;
                    color: $base-color;

                    &.member-info {
                        margin-left: 0;
                        span:first-child {
                            margin-right: 10px;
                        }
                    }
                    span:hover {
                        color: $base-color;
                    }
                    &.el-dropdown:hover {
                        color:$base-color;
                    }
                }
            }
        }
    }
    .mobile-qrcode {
        padding: 10px 0;
    }
	.router-link-active{
		color:$base-color;
	}
	.el-dropdown-language{
		color:$base-color;
	}
</style>
