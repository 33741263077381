export const lang = {
	//公共
	common: {
		//语言包名称
		name: '简体中文',
		metaTitle: '全澳华人严选(Huarenshop) 电器商城',
		login:'您好，请登录',
		register:'免费注册',
		logout:'退出',
		myOrder:'我的订单',
		myHistory:'我的足迹',
		myAttention:'我的关注',
		help:'帮助中心',
		notice:'公告',
		mobile_1:'手机商城',
		goods: "商品",
		shop: "店铺",
		
		allCategory:'所有商品分类',
		
		//订阅
		subTitle:'加入我们获取最新优惠信息',
		subHint:'请输入您的电子邮箱地址',
		subBtn:'订阅',
		
		search:'搜索',
		searchHot:'热门搜索',
		
		coupons: '领券中心',
		myCoupons: '我的优惠券',
		reach: '满',
		available: '可用',
		get: '立即获取',
		more: '更多',
		cart: '购物车',
		myCart:'我的购物车',
		myCartEmpty:'您的购物车是空的，赶快去逛逛，挑选商品吧！',
		latest: '最新文章',
		store: '华人严选（HuarenShop）线上购物商城',
		storead: '全澳华人严选线上购物商城',
		xiaochengxu: '手机移动端',
		postage: '全澳限时无门槛包邮到家',
		guarantee: '支持七天无理由退换货*，澳洲本地售后服务，产品质量问题享一年质保（非人为）只换不修（仅限HuarenShop自营产品）',
		credit_card: '信用卡', 
		we_pay: '微信支付',
		alipay: '支付宝',
		shopping_cart: '最新加入的商品', 
		delete: '删除',
		edit: '编辑',
		cart_summary1: '共',
		cart_summary: ' 种商品，总金额',
		view_cart: '去购物车',
		select_product: '商品精选',
		settlement: '结算',

		//
		member: '会员中心',
		welcome: '欢迎页',
		info: '个人信息',
		security: '账户安全',
		deliveryAddress: '收货地址',
		collection: '我的关注',
		footprint: '我的足迹',
		trading: '交易中心',
		myorder: '我的订单',
		refund: '订单售后',
		verification: '核销台',
		verificationList: '核销记录',
		account: '账户中心',
		money: '账户余额',
		record: '提现记录',
		coupon: '我的优惠券',
		score: '我的积分',
		acclist: '账户列表',
		cancel: '取消',
		confirm: '确定',
		prompt_info: '提示信息',
		delete_success: '删除成功',
		self_support: '自营', 
		piece: '件',
		mobile: '请输入手机号',
		email_error: '邮箱地址格式错误',
		payDynacodeText: '获取动态码',
		reacquire: '后可重新获取',
		vercode: '请输入验证码',
		email_dynacode: '请输入邮箱动态码',
		confirm_password: '请输入确认密码',
		password_twice: '两次密码输入不一致',
		pwd_len_one: '密码长度不能小于',
		pwd_len_two: '位',
		pwd_complexity: '密码需包含',
		numbers: '数字',
		lower_case_letters: '、小写字母',
		uppercase_letter: '、大写字母',
		special_characters: '、特殊字符',
		repeatedly: '请勿重复点击',
		evaluate_num: '条评价',
		payment_num: '人付款',
		limit_discount: '限时折扣',
		postage_title: '邮费：',
		delivery_title: '配送：',
		guarantee_title: '保障：',
		payment_title: '支付方式：',
		price_guarantee_title: '价保：',
		price_guarantee: '15天内价格最低保障* ',
		prevtext: '上一页',
		nexttext: '下一页',

		category: '分类',
		brand: '品牌',
		all_brand: '所有品牌',
		comprehensive: '综合',
		sales: '销量',
		price: '价格',
		fast: '自营',
		lowest_price: '最低价格',
		highest_price: '最高价格',
		no_goods: '没有找到您想要的商品。换个条件试试吧',
		gotop: '返回顶部',
	}
}
