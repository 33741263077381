export const lang = {
	//title为每个页面的标题
	title: '登录',
	welcome: '欢迎登录',
	welcome_register: '欢迎注册',
	label: '密码登录',
	email_code: '邮箱动态码登录',
	mobile_code: '短信动态码登录',
	account: '请输入登陆邮箱',
	password: '请输入登录密码',
	mobile: '请输入手机号',
	vercode: '请输入验证码',
	autoLogin: '7天自动登录',
	findPass: '忘记密码？',
	noAccount: "没有账号？",
	register: '立即注册',
	submit: '登录',
	login_success: '登录成功！'
}
