export const lang = {
	//title为每个页面的标题
	title: '商品详情',
	video: '视频',
	picture:'图片',
	discount: '限时折扣',
	day: '天',
	hour: '小时',
	minute: '分钟',
	seconds: '秒',
	s_price: '销售价',
	o_price: '原价',
	m_price: '会员价',
	mk_price: '市场价',
	evaluate: '累计评价',
	sales: '累计销量',
	brand: '品牌',
	coupon: '优惠券',
	service: '服务',
	details: '商品详情',
	attribute: '商品属性',
	reviews: '商品评价',
	buynow: '立即购买',
	joincart: '加入购物车',
	out_stock: '库存不足',
	quantity: '数量',
	focus_goods: '关注商品',
	bundling: '组合套餐价',
	no_reviews: '该商品暂无评价哦',
	all_review: '全部评价',
	select_attributes: '请选择要购买的产品属性',
}
